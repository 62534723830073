.Page__header{
  padding-top: 120px;
  padding-bottom: 60px;
  background-color: $peach;
  position: relative;
  overflow: hidden;

  @include responsive($tabletSmall){
    padding-top: space(6);
    padding-bottom: space(4);
  }

  .container{
    text-align: center;
    z-index: 1;

    span {
      color: $red;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 11px;
    }
    
    h1 {
      color: $green2;
      margin: 30px 0;

      @include responsive($tabletSmall) {
        margin: get-vw(30) auto;
        max-width: space(26); 
      }
    }

    p {
      margin: auto;
      color: #998F89;

      @include responsive($tabletSmall) {
        max-width: space(20);
      }
    }

    .Btn{
      @include responsive($tabletSmall, max){
        margin-top: 40px;
        align-self: start;
      }

      @include responsive($tabletSmall){
        align-self: end;
        margin-left: auto;
      }
    }
  }

  .icones {
    .visual__container {
      @include cover();
      overflow: visible;

      .visual img {
          object-fit: initial;
      }
    }
  }

  .--subscriptions & {
    background-color: $green1;

    h1 {
      color: $white;
    }

    p {
      color: $pink;
    }
  }

  .icones.--shops {
    
      .red, .magenta, .pink {
        position: absolute;
        width: 100%;
      }

      .red {
        @include imgRatio(147, 157);
        top: 55%; left: -5%;
        max-width: 62px;
        
        @include responsive($tabletSmall) {
          max-width: get-vw(147);
          left: space(4);
        }
      }

      .magenta {
          right: space(9);
          bottom: -5%;
          @include imgRatio(135, 128);
          max-width: 65px;

          @include responsive($tabletSmall) {
              max-width: get-vw(135);
          }
      }

      .pink {
          right: -3%;
          top: 25%;
          @include imgRatio(135, 128);
          max-width: 65px;

          @include responsive($tabletSmall) {
              max-width: get-vw(135);
          }
      }
  }

  .icones.--faq {
    display: block;
    
      .red, .magenta {
        position: absolute;
        width: 100%;
      }

      .red {
          left: space(3);
          top: 25%;
          @include imgRatio(192, 181);
          max-width: 62px;

          @include responsive($tabletSmall) {
            top: 45%;
            max-width: get-vw(192);
          }
      }

      .magenta {
          right: 0;
          bottom: 0;
          @include imgRatio(403, 372);
          max-width: 65px;

          @include responsive($tabletSmall) {
              max-width: get-vw(403);
          }
      }
  }

  .icones.--subscriptions {
    display: block;
    
      .green, .magenta, .pink {
        position: absolute;
        width: 100%;
      }

      .green {
          left: 0;
          top: 55%;
          @include imgRatio(142, 143);
          max-width: 62px;

          @include responsive($tabletSmall) {
              max-width: get-vw(142);
          }
      }

      .pink {
          right: space(5);
          bottom: -5%;
          @include imgRatio(147, 157);
          max-width: 65px;
          z-index: 2;

          @include responsive($tabletSmall) {
              bottom: -10%;
              max-width: get-vw(147);
          }
      }

      .magenta {
        right: -2%;
        top: 25%;
        @include imgRatio(135, 128);
        max-width: 62px;

        @include responsive($tabletSmall) {
            max-width: get-vw(135);
        }
    }
  }

  .--commitments & {
    background: $pink;
    overflow: visible;

    h1, p {
      color: $green1
    }

    .icones.--commitments {
      display: block;
      
        .green, .red {
          position: absolute;
          width: 100%;
        }

        .green {
            left: 0;
            top: 35%;
            @include imgRatio(142, 143);
            max-width: 62px;

            @include responsive($tabletSmall) {
                max-width: get-vw(142);
            }
        }

        .red {
            right: space(3);
            bottom: -5%;
            @include imgRatio(147, 157);
            max-width: 65px;
            z-index: 2;

            @include responsive($tabletSmall) {
                bottom: -10%;
                max-width: get-vw(147);
            }
        }
    }
  }

  &.--error404 {
    .container .Btn, .Archive__header .container .Btn {
      margin: 50px auto;

      .Btn__txt {
        color: white
      }
    }
  }
} 

.Archive__header {
  @extend .Page__header;

  .container {
    max-width: space(29);
    margin: auto;
    z-index: 1;
  }

  .icon1 {
    position: absolute;
    right: space(3);
    bottom: space(3)
  }

  .icon2 {
    position: absolute;
    right: 0%;
    top: 20%;
  }

  .icon3 {
    position: absolute;
    left: space(2);
    top: 40%;
  }

  .icon4 {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}