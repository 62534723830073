.Btn {
  text-align: center;
  background: $green2;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.2;
  padding: 20px 30px;
  outline: 0;
  border: 0;
  cursor: pointer;
  border-radius: 60px;
  @include flex(center,center);
  gap: 12px;
  max-width: max-content;
  margin-top: 30px;
  text-decoration: none!important;

  @include responsive($tabletSmall) {
    padding: get-vw(20) get-vw(30);
    margin-top: get-vw(30);
  }

  &:hover {
      svg {
          transform-origin: 50% 50%;
          transform: rotate(180deg);
      }
  }

  svg {
      transition: all .6s ease-in-out;

      path {
        fill: #073836;
      }
  }

  &__txt {
    color: $white;
    font-weight: 500;
    font-size: 17px;

    @include responsive($tabletSmall) {
      @include font-vw(17);
    }

    @include responsive($deskXL){
        font-size: 17px;
    }
  }
}