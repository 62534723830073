.single-post {

    &__title {
        padding: 120px space(2) 50px;
        background-color: $peach-d1;

        @include responsive($tabletSmall) {
            @include flex(initial,space-between);
            gap: space(5);
            padding: space(6) space(6) get-vw(80);
            margin-bottom: 0;
        }

        .aside {
            @include responsive($tabletSmall,max) {
                margin-bottom: 30px;
            }

            a {
                color: $red;
                margin: 0;

                svg {
                    transform: rotate(180deg);

                    path {
                        stroke: $red;
                    }
                }
            }

            .author {
                @include flex(center,initial);
                gap: 16px;
                margin-top: 30px;

                @include responsive($tabletSmall) {
                    max-width: space(10);
                }

                .picture { 
                    width: 56px;
                    height: 56px;

                    @include responsive($tabletSmall) {
                        width: get-vw(56);
                        height: get-vw(56);
                    }
                }

                img {
                    border-radius: 50%;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }

                span {
                    font-size: 14px;
                    line-height: 1.1;
                    font-weight: 500;

                    @include responsive($tabletSmall) {
                      @include font-vw(14);
                    }

                    @include responsive($deskXL){
                        font-size: 14px;
                    }

                    strong {
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 1.3;

                        @include responsive($tabletSmall) {
                            @include font-vw(18);
                        }

                        @include responsive($deskXL){
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        .main {
            @include responsive($tabletSmall) {
                max-width: space(22);
            }

            .category {
                border: 1px solid $green2;
                border-radius: 48px;
                font-weight: 900;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                padding: 6px 12px;
                color: $green2;
                margin-right: 16px;
            }

            .date {
                font-size: 15px;
                font-weight: 500;
            }
    
            h1 {
                font-size: 55px;
                line-height: 85%;
                margin-top: 30px;
    
                @include responsive($tabletSmall) {
                    margin-top: get-vw(40);
                    @include font-vw(70);
                }

                @include responsive($deskXL){
                    font-size: 40px;
                }
            }
        }
    }

    &__image {
        margin: auto;
        padding: 0 space(2);
        background: linear-gradient(to bottom, $peach-d1 35%, transparent 35%, transparent 100%);

        @include responsive($tabletSmall) {
            padding: 0 space(6);
        }

        img {
            border-radius: 15px;
            margin: auto;
            object-fit: cover;
            height: 350px;
            width: 100%;

            @include responsive($tabletSmall) {
                height: get-vw(600);
            }
        }
    }

    .Page__content {

        .wswyg--content {
            font-weight: 500;

            @include responsive($tabletSmall) {
                max-width: space(24);
                margin: auto;
            }

            h2 {
                text-align: left;
                color: $green2;
                line-height: 0.9;
                margin: 60px 0 24px;
                font-size: 45px;

                @include responsive($tabletSmall) {
                    @include font-vw(52);
                    margin: get-vw(72) 0 get-vw(24);
                }

                @include responsive($deskXL){
                    font-size: 52px;
                }
            }

            h3 {
                font-size: 35px;
                margin: 50px 20px;

                @include responsive($tabletSmall) {
                  @include font-vw(45);
                  margin: get-vw(50) 0 get-vw(20);
                }

                @include responsive($deskXL){
                    font-size: 45px;
                }
            }

            a {
                color: $green2;
            }

            p {
                @include responsive($tabletSmall) {
                    max-width: space(23);                
                }

                strong {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 1.35;

                    @include responsive($tabletSmall) {
                      @include font-vw(20);
                    }

                    @include responsive($deskXL){
                        font-size: 20px;
                    }
                }
            }

            ul {
                padding-left: 25px;

                li {
                    margin: 0;

                    @include responsive($tabletSmall) {
                      @include font-vw(16);
                    }

                    @include responsive($deskXL){
                        font-size: 16px;
                    }
                }
            }

            blockquote {
                margin: 24px 0;
                background: $peach;
                border-radius: 15px;
                border: 0;
                padding: 32px;

                @include responsive($tabletSmall) {
                    padding: get-vw(32);
                }

                h4 {
                    color: $green2;
                    font-weight: 600;
                    margin-bottom: 16px;
                }

                p {
                    font-size: 16px;
                    line-height: 1.6;

                    @include responsive($tabletSmall) {
                        @include font-vw(16);
                    }

                    @include responsive($deskXL){
                        font-size: 16px;
                    }
                }

                strong {
                    @extend p;
                    font-weight: 600;
                }
            }

            img {
                margin: 40px 0;
                height: 300px;
                width: 100%;
                object-fit: cover;
                border-radius: 15px;

                @include responsive($tabletSmall) {
                  margin: get-vw(72) 0;
                  height: get-vw(400);
                }
            }
        }
    }

    &__socials {
        background-color: $magenta;
        color: $white;
        border-radius: 25px;
        padding: 100px 20px;
        margin: 15px;
        text-align: center;
        position: relative;
        overflow: hidden;

        @include responsive($tabletSmall) {
          padding: get-vw(100) 0;
          margin: get-vw(15);
        }

        .container {
            z-index: 1;
            position: relative;

            h3 {
                @include responsive($tabletSmall) {
                    max-width: space(28);
                    margin: auto;
                } 
            }

            .icons {
                @include flex(center,center);
                gap: 12px;
                margin-top: 50px;

                @include responsive($tabletSmall) {
                    margin-top: get-vw(50);
                }

                img {
                    @include responsive($tabletSmall) {
                      width: get-vw(32);
                      height: get-vw(32);
                    }
                }
            }
        }

        .vector {
            position: absolute;
            left: 0;
            bottom: -3%;
            max-width: space(13);
        }
    }

    &__related {
        text-align: center;

        h3 {
            margin: 0 auto 30px;

            @include responsive($tabletSmall) {
                max-width: space(28);
                margin: 0 auto get-vw(30);
            } 
        }
    }
}