.loop {
    @include flex(stretch, center);
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 40px;
    width: 100%;

    @include responsive($tabletSmall) {
        gap: get-vw(30);
    }

    .archive__list & {
        @include responsive($tabletSmall) {
            justify-content: flex-start;
        }
    }

    > * {
        width: 100%;
        max-width: 320px;

        @include responsive($tabletSmall) {
            max-width: calc(25% - get-vw(30));
        }
    }
}

article.entry {
    position: relative;

    .media-figure  {
        position: relative;
        @include imgRatio(330, 400, $peach-d2);
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: get-vw(15);
        transition: transform 420ms $ease;
        
        .other-products:not(.--flowers) &:before {
            background: darken($peach, 3%);
        }

        .blog__products .other-products &:before {
            background: darken($peach, 8%);
        }

        > a {
            display: block;
            @include cover();
        }

        img {
            @include cover();
            object-fit: cover;
            mix-blend-mode: darken;
        }
    }

    .onsale {
        display: none;
    }

    .entry-title {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.3;
        font-family: $main-font;

        @include responsive($tabletSmall) {
            @include font-vw(18);
        }

        @include responsive($deskXL){
            font-size: 18px;
        }

        a {
            color: $green1;
        }
    }

    .price {
        .amount {
            color: $green2;
        }

        del {
            margin-right: 5px;
            opacity: 0.7;

            .amount {
                text-decoration:line-through
            }
        }

        @include responsive($tabletSmall) {
            @include font-vw(14);
        }

        @include responsive($deskXL){
            font-size: 14px;
        }
    }

    &.product-type-variable {
        .amount {
            bdi {
                &::before {
                    content: 'A partir de ';
                }
            }

            &:last-child {
                display: none;
            }
        }
    }

    .button {
        display: none;
    }

    &:hover {
        .media-figure {
            transform: rotate(3deg);
        }
        // HOVER CARD
    }
}

.ArchiveProduct .loop .price,
.single__product .loop .price {
    color: $white;
}