.history {
    position: relative;
    padding: 0;
    overflow: hidden;
    margin: 15px 0;

    .container {
        padding: 0;
        @include flex(center,center);
        max-width: none!important;
    }
    
    &__dates {
        padding: space(1) space(1) space(1) 0;
        font-weight: 900;
        font-size: 17px;
        line-height: 1.4;
        color: #A1BBBA;

        @include responsive($tabletSmall, max) {
            display: none;
        }

        @include responsive($tabletSmall) {
            flex-basis: 49%;
            padding: get-vw(500) space(8) get-vw(190) space(2);
            text-align: right;
        }

        li {
            margin-bottom: 8px;

            &.link {
                font-size: 17px;
                font-weight: 900;
                transition: color 0.15s ease-in-out;
                @include flex(center,flex-end);
                gap: 6px;
                cursor: pointer;
    
                @include responsive($tabletSmall) {
                  @include font-vw(17);
                }

                @include responsive($deskXL){
                    font-size: 17px;
                }

                &::after {
                    content: '';
                    background: $red;
                    width: 0;
                    height: 2px;
                    transition: width 0.2s ease-in-out;
                }
    
                &.--active, &:hover {
                    color: $red;

                    &::after {
                        width: get-vw(16);
                    }
                }
            }
        }
    }

    &__text {
        overflow: hidden;
        background: $pink;
        border-radius: 25px;
        padding: get-vw(500) 30px 30px;

        @include responsive($tabletSmall, max) {
            margin: 0 15px;
        }

        @include responsive($tabletSmall) {
            flex-basis: 49%;
            width: 49%;
            padding: get-vw(500) space(2) get-vw(190);
        }

        .inner {
            @include responsive($tabletSmall, max) {
                max-width: 380px;
                margin: 0 auto;
            }
        }

        p {
            margin-bottom: 16px;

            @include responsive($tabletSmall) {
                margin-bottom: get-vw(16);
                max-width: space(15);
            }

            strong {
                font-weight: 900;
            }
        }

        h3 {
            font-size: 24px;
            font-family: $main-font;
            font-weight: 700;

            @include responsive($tabletSmall) {
                @include font-vw(24);
            }

            @include responsive($deskXL){
                font-size: 24px;
            }
        }

        .swiper {
            @include responsive($tabletSmall) {
                margin-right: space(-2);
            }

            &-wrapper {
                order: 1!important;
            }

            &-navigation{
                justify-content: center;
                margin-bottom: 0!important;

                @include responsive($tabletSmall){
                  display: none;
                }

                > *{
                    position: relative;

                    &.next{
                        svg:nth-child(2){
                            transform: translate(-50%, -50%) rotate(180deg);
                        }
                    }

                    svg:first-child{
                        *{ stroke: $white }
                    }

                    svg:nth-child(2){
                        position: absolute;
                        top: 50%; left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }

    &__circle {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        transform: translate(0vw, 75vw);

        @include responsive($tabletSmall) {
            transform: translate(5vw, 90vw);
        }

        &__wrapper {
            @include cover();
            z-index: 10;
            pointer-events: none;
            max-height: 90vw;

            @include responsive($tabletSmall) {
                max-height: initial;
                //transform: initial !important;
            }
        }

        &__inner {
            animation: dateCircle 82000ms infinite linear;
        }

        img {
            width: 100%;
        }

        div {
            font-family: $title-font;
            color: $red;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include font-vw(200);
            transform-origin: bottom center;

            @include responsive($deskXL){
                font-size: 200px;
            }

            .inner {
                @include flex(center, center);
                padding-bottom: 210vw;
            }

            .date {
                display: block;
                line-height: 1;
                position: relative;
                top: 0.1em;
                width: 44vw;
                transform-origin: bottom center;
                transform: translate(5%, 15%) rotate(-12deg);

                @include responsive($tabletSmall) {
                    transform: translate(3%, 55%) rotate(-12deg);
                }

                svg {
                    width: 100%;
                    overflow: visible;
                    fill: $red;
                    font-size: 200px;
                    text-align: center;

                    path {
                        opacity: 0;
                    }
                }
            }

            .flower {
                width: get-vw(200);
                margin-left: 0.1em;

                img {
                    max-width: 100%;
                    animation: dateCircle 12000ms infinite linear;
                }
            }
        }
    }
}

@keyframes dateCircle {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}