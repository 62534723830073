a:not([class]) {
  color: $base;
  text-decoration: underline;
}

.Anchors {
  &__nav {
    @include flex();
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: -10px;
  }

  &__item {
    margin-right: 20px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.Link {
  position: relative;
  font-size: 24px;

  // &:after,
  // &:before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 100%;
  //   left: 0;
  //   width: 100%;
  //   height: 1px;
  //   background: $base;
  //   transform-origin: right center;
  //   transition: transform 400ms $easeInOutQuad 50ms;
  // }

  &:before {
    transform: scaleX(0);
    transform-origin: left center;
    transition-delay: 0;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transition-delay: 250ms;
    }

    &:after {
      transform: scaleX(0);
      transition-delay: 0;
    }
  }

  &.--min {
    font-size: 16px;
    font-weight: 500;
  }
}

.link {
  a {
    color: $green2;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    @include flex(center, center);
    gap: 6px;
    max-width: max-content;
    margin: auto;

    .circles {
      transition: transform 550ms $easeInOutCirc;
      transform-origin: 50% 50%;
    }

    .arrow {
      transform: translate(35%, 35%)
    }

    &:hover {
      .circles {
        transform: scale(0.95) rotate(90deg);
      }
    }

    &.--red {
      svg {
        path {
          stroke: $red
        }
      }
    }
  }
}