.boutiques__list {
    @include responsive($tabletSmall) {
        padding: get-vw(120) 0 get-vw(150) 0;
    }

    .container {
        padding: 0 space(4);

        .boutiques__card {
            margin-bottom: 45px;
    
            @include responsive($tabletSmall) {
              margin-bottom: get-vw(90);
              @include flex(center, initial);
              gap: space(3);  
            }
    
            .image {
                flex-basis: 38%;
    
                .visual__container {
                    @include imgRatio(544, 380, $base);
                    -webkit-mask-image: url('../imgs/shape.png');
                    mask-image: url('../imgs/shape.png');
                    -webkit-mask-position: top left;
                    mask-position: top left;
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                    -webkit-mask-size: 100%;
                    mask-size: 100%;
                }
            }
    
            .wswyg--content {
                span.subtitle {
                    font-weight: 900;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: $green2;
                }
    
                h3 {
                    margin: 16px 0 0 0;
    
                    @include responsive(tabletSmall) {
                        margin: get-vw(16) 0 0 0;
                    }
                }

                h5 {
                    font-weight: 500;
                }
            }
    
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

}