.values__block {
    position: relative;
    background: $peach-d1;
    padding:0;

    .headline {
        @include cover();

        .sticky {
            position: sticky;
            top: 0;
            @include flex(center, center);
            height: 100vh;
        }
    }

    h2 {
        @include font-vw(200);
        color: $green2;
        position: relative;
        max-width: max-content;
        margin: auto;

        @include responsive($deskXL){
            font-size: 200px;
        }

        span {
            transform: rotate(-8.28deg);
            color: $green1;
            position: absolute;
            top: -55%;
            left: -25%;
        }
    }

    .colored-cards {
        @include flex(stretch, flex-start);
        flex-wrap: wrap;
        padding-top: 80vh;
        padding: 80vh space(1) space(4);
        gap: space(1);
        position: relative;


        @include responsive($deskXL) {
            max-width: 1660px;
            margin: 0 auto;
        }


        .card {
            position: relative;
            transform: none;
            filter: none;
            width: 100%;
            max-width: 320px;
            margin: 0 auto;

            @include responsive($mobile) {
                max-width: calc(50% - space(1));
                margin: 0;
            }

            @include responsive($tablet) {
                max-width: calc(33.333% - space(1));
            }

            /* &:nth-child(2n + 1) {
                @include responsive($tablet, max) {
                    top: space(8);
                }
                
                @include responsive($mobile, max) {
                    top: 0;
                }
            }

            &:nth-child(3n + 1) {
                @include responsive($tablet) {
                    top: space(4);
                }
            }

            &:nth-child(3n + 3) {
                @include responsive($tablet) {
                    top: space(8);
                }
            }*/

            &:nth-child(1) {
                background: $magenta;
            }

            &:nth-child(2) {
                background: $red;
            }

            &:nth-child(3) {
                background: $green1;

                .card__content {
                    border-color: $green2;

                    h3, p {
                        color: $white;
                    }
                }
            }

            &:nth-child(4) {
                background: $pink;

                .card__content {
                    border-color: #FFF1ED;

                    h3 {
                        color: $red;
                    }

                    p {
                        color: $black;
                    }
                }
            }

            &:nth-child(5) {
                background: $green2;

                .card__content {
                    border-color: $green1;
                }
            }
        }
    }
}