.img-full {
    padding: 0;
    position: relative;
    overflow: hidden;

    .visual__container {
        @include imgRatio(1,1);
        width: 100%;
        margin-bottom: -15%;

        @include responsive($tabletSmall) {
            @include imgRatio(1440, 500);
            margin-bottom: -5%;
        }

        .visual {
            transform: none;

            img {
                transform: scale(1.1);
            }
        }
    }
}