.App {
  width: 100%;
  min-height: 100dvh;
  background: $white;
  position: relative;
  z-index: 10;

  html:not(.no-js) body.--loading & {
    // pointer-events: none;
  }

  html.--dark & {
    background: $base;
    color: $white;
  }

  > * {
    width: 100%;

    &.--commitments,
    &.--shops,
    &.--subscriptions {
      overflow: hidden;
    }

    & + * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.container {
  width: 100%;
  padding: 0 24px;
  position: relative;

  @include responsive($tabletSmall) {
    padding: 0 calc(100vw / 24);
  }

  @include responsive($deskXL) {
    max-width: 1640px;
    margin: 0 auto;
  }
}

section {
  padding: 5em 0;

  @include responsive($tabletSmall) {
    padding: get-vw(100) 0;
  }
}