.colored-cards {
    @include cover();
    overflow: hidden;

    .wrapper {
        @include flex(center, flex-start, inline-flex);
        min-height: 100vh;
    }
    
    .card {
        border-radius: 15px;
        color: $white;
        min-width: 320px;
        padding: 13px;

        @include responsive($tabletSmall) {
            padding: get-vw(15);
            min-width: get-vw(400);
            max-width: get-vw(400);
            border-radius: get-vw(15);
        }

        @include responsive($deskXL){
            min-width: 480px;
            max-width: 480px;
        }

        &__content {
            border: 3px solid $pink;
            border-radius: 12px;
            padding: 20px;
            height: 100%;

            @include responsive($tabletSmall) {
                border-radius: get-vw(12);
                padding: space(1);
            }

            img {
                margin: 0 auto 30px;
                max-width: 160px;

                @include responsive($tabletSmall) {
                    max-width: get-vw(160);
                    margin: 0 auto get-vw(30);
                }
            }    

            h3 {
                font-size: 32px;
                line-height: 0.9;
                text-align: center;

                @include responsive($tabletSmall) {
                  @include font-vw(54);
                }

                @include responsive($deskXL) {
                    font-size: 54px;
                }
            }

            p {
                font-size: 16px;
                line-height: 1.4;
                @include responsive($tabletSmall) {
                  @include font-vw(20);
                }

                @include responsive($deskXL) {
                    font-size: 20px;
                }
            }
        }

        &:nth-child(1) {
            background: $red;
            //transform: matrix(1, -0.07, 0.05, 1, 0, 0);
        }

        &:nth-child(2) {
            background: $magenta;
            //transform: rotate(4deg);
        }

        &:nth-child(3) {
            background: $pink;
            //transform: matrix(1, -0.07, 0.05, 1, 0, 0);

            .card__content {
                border-color: #FFF1ED;

                h3 {
                    color: $red;
                }

                p {
                    color: $black;
                }
            }
        }

        &:nth-child(4) {
            background: $green1;
            //transform: rotate(2deg);
            //filter: drop-shadow(0px 44px 94px rgba($green1, 0.3));

            .card__content {
                border-color: $green2;
            }
        }
    }
}