.teams__block {
    padding: 150px 0;
    overflow: hidden;

    @include responsive($tabletSmall) {
        padding: get-vw(150) 0;
    }

    h2 {
        margin: 0
    }

    .swiper {
        width: 70%;
        margin: 0 auto;

        .swiper-slide {

            .mySlide {
                margin: auto;
                border-radius: 15px;
                overflow: hidden;

                .visual__container {
                    @include imgRatio(2560, 1708, $base);
                }

                span {
                    position: absolute;
                    color: $white;
                    font-weight: 500;
                    font-size: 20px;
                    z-index: 1;
        
                    @include responsive($tabletSmall) {
                        bottom: get-vw(40);
                        left: get-vw(40);
                    }
                }

                &::after {
                    content: '';
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    border-radius: 15px;
                }
            }
        }

        .swiper-navigation {
            justify-content: center;
            margin: 24px 0 32px;

            @include responsive($tabletSmall) {
              margin: get-vw(24) 0 get-vw(32);
            }

            svg.arrow {
                position: absolute;
                left: 37%;
                top: 40%;
            }

            .prev {
                position: relative;
            }

            .next {
                position: relative;

                .arrow {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .Btn {
        margin: 64px auto 0;

        @include responsive($tabletSmall) {
            margin: get-vw(64) auto 0;
        }

        &__txt {
            max-width: max-content;
        }
    }
}