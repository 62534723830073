.Home {

    &__header {
        padding: 190px space(1) 0;
        background: $peach;
        text-align: center;
        z-index: 1;
        position: relative;
        padding-bottom: get-vw(420)!important;

        @include responsive($tabletSmall) {
          padding: get-vw(220) 0 0 0;
          padding-bottom: get-vw(360)!important;
        }

        &+* {
            padding-top: get-vw(420)!important;

            @include responsive($tabletSmall) {
                padding-top: get-vw(360)!important;
            }
        }

        span.subtitle {
            font-size: 20px;
            text-transform: none;
            font-family: 'Gulax';
            line-height: 1;
            color: $red;
            letter-spacing: normal;
            font-weight: 400;

            @include responsive($tabletSmall) {
                @include font-vw(40);
            }

            @include responsive($deskXL){
                font-size: 40px;
            }
        }

        h1 {
            color: $green2;
            font-size: 10vw;
            line-height: 0.9;
            margin-top: 16px;
            text-align: center;

            @include responsive($tabletSmall) {
              margin-top: get-vw(16);
              @include font-vw(130);
            }

            @include responsive($deskXL){
                font-size: 130px;
            }

            span {
                white-space: nowrap;
                @include flex(center, flex-start, inline-flex);
                gap: 0.1em;

                &:nth-child(2) {
                    .vector {
                        order: 1;
                    }

                    span {
                        order: 2;
                    }
                }

                &:nth-child(3) {
                    position: relative;
                    top: -0.1em;
                }
            }

            .vector {
                position: relative;
                @include imgRatio(1, 1);
                width: 0.7em;

                img {
                    @include cover();
                }
            }
        }

        p {
            font-size: 16px;
            margin: 40px auto 60px;
            padding: 0 space(1);

            @include responsive($tabletSmall) {
              @include font-vw(24);
              line-height: 1.3;
              max-width: space(18);
              margin: get-vw(40) auto get-vw(60);
              padding: 0;
            }

            @include responsive($deskXL){
                font-size: 24px;
                max-width: 540px;
            }

            strong {
                display: inline-block;
                background: $red;
                padding: 0 0.5em;
                border-radius: 0.2em;
                color: $white;
                font-weight: bold;
                font-size: 1.1em;
                transform: rotate(-2deg);
                white-space: nowrap;
            }
        }

        .link a {
            color: $red;

            svg {
                path {
                    stroke: $red;
                }
            }
        }

        .gallery__container {
            pointer-events: none;
            @include cover();
            bottom: -50vh;
            height: auto;
            top: 0;
            overflow: hidden;
        }

        .gallery {
            position: absolute;
            margin: auto;
            top: calc(100% - 50vh);
            left: 50%;
            transform: translate(-50%, -50%);
            @include flex(center, center);

            .visual__container {
                position: relative;
                @include imgRatio(400, 540);
                width: 25vw;

                @include responsive($tabletSmall) {
                    width: get-vw(380);
                    max-width: 580px;
                }
    
                &:nth-child(1) {
                    z-index: 3;
                    transform: rotate(-18.03deg);
                }
    
                &:nth-child(2) {     
                    z-index: 2;
                    transform: rotate(-6.49deg);
                    margin: -5% -20% 0 -20%;
                }
    
                &:nth-child(3) {
                    z-index: 1;
                    transform: rotate(4.18deg);
                }
            }
        }
        
    }

    .other-products.--flowers {
        background: $white;
    }
}