/* Panier */

.select2-results__options {
    max-height: 1000px !important;
}

.Cart {
    background: $peach-d1;
    min-height: 100dvh;
    overflow: hidden;

    .Page__header {
        position: relative;
        padding-bottom: 0;
        z-index: 2;
        background: 0;
    }

    .Page__content {
        position: relative;
        padding-bottom: 60px;
    }

    &__content {
        position: relative;
        background: $white;
        padding: 10px;
        z-index: 2;
        padding: 10px;
        padding-bottom: 40px;
        margin: 0 -10px;
        border-radius: 12px;

        @include responsive($tablet) {
            padding: space(1);
            padding-bottom: space(2);
            border-radius: get-vw(25);
            max-width: space(32);
            margin: 0 auto;
        }
    }

    .vector {
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 320px;
        overflow: hidden;

        &.--right {
            right: 0;
            top: 10%;

            @include responsive($desk, max) {
              max-width: 30vw;
            }
        }

        &.--left {

            @include responsive($desk, max) {
                max-width: 30vw;
            }
        }
    }

    .cart_totals .woocommerce-shipping-total, .cart_totals .shipping {
        display:none!important;
    }
}

.woocommerce .cart-collaterals {

    .cross-sells {
        margin: 60px auto;
        float: none;

        @include responsive($tabletSmall) {
            margin: space(2) auto;
        }


        h2 {
            font-size: 32px;

            @include responsive($tabletSmall) {
              @include font-vw(32);
            }

            @include responsive($deskXL){
                font-size: 32px;
            }
        }
    }
}

.woocommerce table.shop_table {
    border-width: 2px;
    margin-bottom: 40px;

    a {
        color: $green2;
    }
}

.woocommerce-error, .woocommerce-info, .woocommerce-message {
    border: 0;
    background: transparent;
    margin: 0;
    padding: 0;
    font-weight: 600;

    @include responsive($tabletSmall) {
      @include font-vw(16);
    }

    @include responsive($deskXL){
        font-size: 16px;
    }

    &::before {
        display: none;
    }
}

.woocommerce-error {
    color: $error-color!important;
}

.woocommerce-notices-wrapper {
    text-align: center
}

.cart-empty {
    font-size: 20px;
    text-align: center;
    padding-top: 40px;

    @include responsive($tabletSmall) {
        @include font-vw(30);
        padding-top: get-vw(40);
    }

    @include responsive($deskXL){
        font-size: 30px;
    }

    &::before {
        display: none;
    }
}

.woocommerce-cart-form {

    .woocommerce-message {
        margin-bottom: 20px;
        color: $green2
    }

    .cart_item {
        .product-thumbnail {
            img {
                max-width: 100px;
            }
        }
    }

    input#coupon_code.input-text {
        width: 150px;
        text-align: left;
    }

    .coupon {
        border: 1px solid $peach;
        padding: 6px;
        border-radius: 60px;

        input {
            padding-left: 10px;
            color: $black;
            min-height: 30px;
            border: 0;
            border-radius: 60px;
        }

        button.wp-element-button {
            font-size: 14px;
            padding: .618em 1em!important;
        }
    }

    .wac-qty-button {
        border: 0
    }
}

.woocommerce-shipping-methods {
    .pickup-location-field {
        .pickup-location-address {
            font-size: .8em;
        }
    }
}

.woocommerce .cart-collaterals .cart_totals {
    float: initial;
    margin: 0px auto;
    width: 100%;

    @include responsive($tablet) {
        max-width: space(32);
    }

    h2 {
        display: none;
    }

    .order-total {
        background: $peach;

        bdi {
            color: $green2;
            font-weight: 600;
        }
    }
}

.return-to-shop .wc-backward,
.wc-proceed-to-checkout .checkout-button {
    color: $white!important;
    background: $green2!important;
    padding: 20px 30px!important;
    border-radius: 60px!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    gap: 10px!important;
    max-width: max-content;
    margin: 30px auto 0 auto!important;
    &::after {
        content: url('../../dist/imgs/SVG/icon-btn.svg');
        transition: all .3s ease-in-out
    }
    &:hover {
        &::after {
            transform-origin: 50% 45%;
            transform: rotate(180deg);
        }
    }
}

.Cart.--commande {

    .Page__header {
        padding-top: 120px;

        @include responsive($tabletSmall) {
            padding-top: get-vw(140);
        }

        h1 {
            font-size: 10vw;

            @include responsive($tabletSmall){
                @include font-vw(58);
            }
        }
    }

    .Page__content {
        padding-top: 30px;

        @include responsive($tabletSmall) {
            padding-top: get-vw(30);
        }
    }

    .woocommerce {
        max-width: 920px;
        padding: 40px;
        background: $white;
        border-radius: 12px;
    }
}

/* Validation commande */
.woocommerce-checkout {

    /* @include flex(flex-start, space-between);
    flex-wrap: wrap;
    gap: space(3);
    margin-top: 40px;

    > * {
        width: 100%;

        @include responsive($tabletSmall) {
            max-width: calc(50% - space(1.6));
        }
    } */

    .woocommerce-NoticeGroup {
        min-width: 100%!important;
        ul {
            padding: 0;
        }
    }

    h3 {
        text-align: left!important;
        font-family: $main-font;
        font-size: 22px;
        font-weight: 600;
        color: $green2;

        @include responsive($tabletSmall) {
          @include font-vw(30);
        }

        @include responsive($deskXL){
            font-size: 30px;
        }

        label {
            cursor: pointer;
            @include flex(flex-start, flex-start);
            gap: 10px;
            font-size: 0.5em;
            text-decoration: underline;
        }
    }

    #customer_details {
        border-bottom: 1px solid $beige;
        padding-bottom: 40px;
        margin-top: 40px;

        /* @include responsive($tabletSmall) {
            @include flex(flex-start, space-between);
        }

        > * {
            @include responsive($tabletSmall) {
                width: calc(50% - 20px);
                border: 1px solid $beige;
                padding: 30px;
                border-radius: 12px;
            }
        } */

        .input-text, select {
            border: 1px solid rgba(7, 56, 54, 0.12);
            border-radius: 15px;
            padding: 15px;

            .select2 span {
                width: 100%;

                .select2-selection {
                    border: 1px solid rgba(7, 56, 54, 0.12)
                }
            }
        }

        textarea {
            min-height: 100px;
        }

        .woocommerce-input-wrapper {
            width: 100%;
        }
    }

    #review {
        margin: 40px auto 0;
        max-width: 830px;
    }

    .woocommerce-shipping-fields {
        border-top: 1px solid $beige;
        border-bottom: 1px solid $beige;
        padding: 40px 0;
        margin: 40px 0;

        h3 {
            margin: 0;
        }

        .shipping_address {
            margin-top: 30px;
        }
    }

    .woocommerce-checkout-review-order {
        .order-total {
            background: $peach;

            bdi {
                color: $green2;
                font-weight: 600;
            }
        }
    }

    .woocommerce-checkout-payment {
        button {
            background-color: $green2!important;
        }

        fieldset {
            margin: 0;
            label {
                font-size: 12px!important;
                white-space: nowrap;
            }
        }
    }
}
