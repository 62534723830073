.BrandOLF{
    @include imgRatio(1536, 590);
    width: 100%;
    overflow: hidden;
    margin-top: 5em;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: center;
      z-index: 0;
      

      &.o {
        @include imgRatio(585, 549);
        width: get-vw(585);
        svg { transform: translate(2%, -2%); }
      }

      &.l {
        @include imgRatio(283, 566);
        width: get-vw(283);
        svg { transform: translate(20%, -9%); }
      }

      &.f {
        @include imgRatio(346, 660);
        width: get-vw(346);
        svg { transform: translate(18%, 5%); }
      }

      &.flower {
        @include imgRatio(340, 324);
        width: get-vw(340);
        svg { transform: translate(1%, -5%); }
      }
    }
  
    svg {
      @include cover();
      overflow: visible;
    }
  }
  