.commitments {
    background: $peach-d1;
    padding: 60px space(1);

    @include responsive($tabletSmall) {
      padding: get-vw(120) space(2);
    }

    &__text {
        @include responsive($tabletSmall) {
            @include flex(center,space-between);
            gap: 80px;
        }

        div {
            @include responsive($tabletSmall) {
                flex-basis: 45%;
            }

            h2 {
                text-align: left;
                margin-bottom: 32px;

                @include responsive($tabletSmall) {
                    @include font-vw(65);
                }

                @include responsive($deskXL){
                    font-size: 65px;
                }
            }

            p {
                max-width: 90%;

                @include responsive($tabletSmall, max) {
                    margin-bottom: 30px;
                }
            }
        }

        .postcard {
            background: $white;
            border-radius: 8px;
            padding: 10px;
            transform: rotate(-3deg);

            @include responsive($tabletSmall, max) {
                max-width: 500px;
                margin: auto;
            }

            @include responsive($tabletSmall) {
                padding: get-vw(10);
                flex-basis: 55%;
            }

            &__content {
                border: 3px solid #EBEBEB;
                border-radius: 8px;
                padding: 10px 30px 20px 30px;

                .vector {
                    @include flex(center,flex-end);
                    gap: 15px;

                    .postcard1 {
                        max-width: 90px;

                        @include responsive($tabletSmall) {
                            max-width: get-vw(180);
                        }

                        img {
                            width: 100%;
                        }
                    }

                    .postcard2 {
                        @include imgRatio(100,138);
                        max-width: 60px;
                        width: 100%;
                        position: relative;
            
                        @include responsive($tabletSmall) {
                            max-width: get-vw(100);
                        }
            
                        .visual__container {
                            @include cover();
                            overflow: visible;
                            max-width: 100px;
            
                            @include responsive($tabletSmall) {
                                max-width: get-vw(100);
                            }
            
                            img {
                                object-fit: contain;
                            }
                        }
                    }
                }
    
                span {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1.3;
                    margin-top: 50px;
    
                    @include responsive($tabletSmall) {
                      @include font-vw(20);
                      margin-top: get-vw(60);
                      max-width: 75%;
                    }

                    @include responsive($deskXL){
                        font-size: 20px;
                    }
                }
            }
        }
    }

    &__icones {
        margin-top: 70px;

        @include responsive($tabletSmall) {
          margin-top: get-vw(80);
          @include flex(center,space-between);
          gap: 20px;
        }

        div {
            margin-bottom: 30px;

            @include responsive($tabletSmall) {
                max-width: space(8);
                margin: 0;
            }

            img {
                max-width: 85px;
                margin: 0 auto 20px;

                @include responsive($tabletSmall) {
                    max-width: get-vw(105);
                    margin: 0 auto get-vw(30);
                }
            }
    
            p {
                font-weight: 600;
                text-align: center;
            }
        }
    }
}