.ContactForm {
    background: $peach-d1;
    padding-top: 120px;
    position: relative;
    overflow: hidden;
  
    @include responsive($tabletSmall){
      padding-top: space(6);
    }

    &__title {
        text-align: center;
        margin-bottom: get-vw(60);
        padding: 0 space(2);
        position: relative;
        z-index: 1;

        span {
            color: red;
            font-weight: 900;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }

        h1 {
            color: $green2;
            margin: 30px 0;

            @include responsive($tabletSmall) {
              margin: get-vw(30);
            }
        }

        p {
            color: #998F89
        }
    }

    .container {
        position: relative;
        z-index: 1;
    }
    .vector {
        position: absolute;
        z-index: 0;

        &.--right {
            right: 0;
            top: space(6);

            @include responsive($desk, max) {
              max-width: 40vw;
              top: 10%;
            }
        }

        &.--left {
            bottom: 0;
            left: space(2);

            @include responsive($desk, max) {
                max-width: 40vw;
                left: 0;
            }
        }
    }
}