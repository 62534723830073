$menuBreakpoint: $tablet;

.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none;

  .container {
    width: 100%;
    max-width: none;
    @include flex(center, space-between);
    padding-top: 15px;
    padding-bottom: 15px;
    gap: 20px;

    @include responsive($tabletSmall) {
      padding-top: get-vw(20);
      padding-bottom: get-vw(20);
      transition: background-color 420ms cubic-bezier(0.36, 0.33, 0, 1), padding 420ms cubic-bezier(0.36, 0.33, 0, 1), transform 420ms cubic-bezier(0.36, 0.33, 0, 1);
    }

    .--scrolled & {
      background-color: $peach;

      @include responsive($tabletSmall) {
        backdrop-filter: blur(10px);
      }

      @include responsive($tabletSmall) {
        padding-top: get-vw(14);
        padding-bottom: get-vw(14);
      }

      &:not(.--visible) {
        @include responsive($tabletSmall) {
          transform: translateY(-100%);
        }
      }

      .Brand {
        width: 120px;
        @include imgRatio(234, 70);

        svg {
          display: none;
          &+svg {
            display: block;
          }
        }

        @include responsive($tabletSmall) {
          width: get-vw(130);
        }

        @include responsive($deskXL) {
          width: 130px;
        }
      }
    }

    > div:nth-child(2) {
      @include responsive($menuBreakpoint) {
        position: relative;
        z-index: 2;
      }
    }
  }

  .Brand {
    position: relative;
    @include imgRatio(91, 78);
    width: 90px;
    transition: width 420ms $ease;

    @include responsive($tabletSmall) {
      width: get-vw(90);
    }

    @include responsive($deskXL) {
      width: 90px;
    }

    svg {
      @include cover();

      &+svg {
        display: none;
      }
    }
  }

  .mobile {
    @include flex(center,center);
    gap: 13px;
  }

  .account {
    @include flex(center,center);
    gap: 15px;
    pointer-events: initial;
    z-index: 1;

    a {
      position: relative;
    }

    .count {
      @include flex(center, center);
      background: $red;
      color: $white;
      position: absolute;
      top: 0;
      right: 0;
      font-weight: 900;
      font-size: 8px;
      line-height: 1;
      height: 16px;
      font-family: $main-font;
      border-radius: 30px;
      text-align: center;
      padding: 0 4px;
      min-width: 16px;
      transform: translate(40%, -40%);

      @include responsive($tabletSmall) {
        min-width: get-vw(16);
      }
    }

    body.showMenu & svg {
      path {
        fill: $white
      }
    }
  }

  .--commitments & {
    .Nav__sub button {
      border: 1px solid #E4B8B0;
    }  
  }

  .--subscriptions:not(.--scrolled) &,
  .--archive:not(.--scrolled) & {
    color: $white;

    .Nav__link:after {
      background: $white;
    }

    .Nav__sub ul {
      color: initial;
    }

    .Nav__sub button {
      border: 1px solid #49A6A8;
    }

    svg {
      .letter {
        fill: $white
      }
    }

    .account {
      svg {
        path {
          fill: $white
        }
      }
    }
  }
}
