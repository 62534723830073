.txtimg {
    position: relative;
    margin: 15px;
    padding: 0;
    @include flex(stretch,space-between);
    flex-direction: column-reverse;

    @include responsive($tabletSmall) {
        flex-direction: row;
    }

    &__text {
        padding: space(4) space(2);

        @include responsive($tabletSmall) {
            padding: get-vw(200) space(5) get-vw(200) space(3);
            flex-basis: 50%;
        }

        h2 {
            text-align: left;
            margin-bottom: 20px;
            line-height: 85%;

            @include responsive($tabletSmall) {
                margin-bottom: get-vw(30);
            }
        }
    }

    &__image {
        position: relative;
        border-radius: 25px;
        background: $red;
        padding: 30px;
        @include flex(flex-end,flex-start);
        z-index: 2;
        padding-bottom: 0;

        @include responsive($tabletSmall) {
            flex-basis: 50%;
            padding: space(1);
        }

        .visual__container {
            @include imgRatio(580, 600);
            border-radius: 300px 300px 15px 15px;
            max-width: 70%;

            @include responsive($tabletSmall) {
                border-radius: get-vw(300) get-vw(300) 15px 15px;
            }
        }
    }

    .flower {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        max-width: space(20);
        @include imgRatio(147, 157);
        transform: translate(20%, -20%);
        z-index: 1;
        
        @include responsive($tabletSmall) {
            max-width: get-vw(740);
        }

        > div {
            @include cover();
        }

        svg {
            @include cover();

            path { 
                fill: $green2;
            }
        }
    }

    &.--green {
        background: $green1;
        border-radius: 25px;

        .txtimg__text {
            color: $white;

            @include responsive($tabletSmall) {
                padding: get-vw(220) space(3) get-vw(220) space(5);
            }
        }

        .txtimg__image {
            background: transparent;
            @include flex(center,initial);

            @include responsive($tabletSmall) {
                margin-left: space(1);
            }

            .visual__container {
                max-width: 76%;
            }
        }
    }
}