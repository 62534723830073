.blog__list,
.single-post__related {
    .list,
    #hiddenPosts {
        .card {
            border-radius: 15px;
            background-color: $peach;
            text-align: center;
            @include flex(center, space-between);
            flex-direction: column;
            gap: 0px;
            padding-bottom: 30px;
    
            @include responsive($tabletSmall) {
                padding-bottom: get-vw(30);
            }

            div {
                width: 100%;
            }

            .visual__container {
                @include imgRatio(450, 270, $base);
                margin-bottom: 30px;
                border-radius: 15px 15px 0px 0px;
    
                @include responsive($tabletSmall) {
                    margin-bottom: get-vw(30);
                    border-radius: get-vw(15) get-vw(15) 0px 0px;
                }    

                @include noTouchHover() {
                    .visual { transform: scale(1.05); }
                }

                .visual {
                    transition: transform 640ms $ease;
                }
            }
    
            .text {
                padding: 0 space(1) get-vw(40);
    
                .category {
                    border: 1px solid $green2;
                    border-radius: 48px;
                    font-weight: 900;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    padding: 6px 12px;
                    color: $green2;
                    margin-bottom: 20px;
                }
        
                h4 {
                    margin-bottom: 16px;
                    font-weight: 600;
                }
        
                .author {
                    color: $green2;
                    font-weight: 600;
                    font-size: 14px;
        
                    @include responsive($tabletSmall) {
                      @include font-vw(14);
                    }

                    @include responsive($deskXL){
                        font-size: 14px;
                    }
                }    
            }
    
            .link a {
                color: $red;
    
                svg {
                    path {
                        stroke: $red;
                    }
                }
            }
        }
    }
}