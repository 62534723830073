.archive__list {
    padding: 60px space(2) 120px space(2);

    @include responsive($tabletSmall) {
        padding: get-vw(60) space(2) get-vw(120) space(2);
    }

    .woocommerce-breadcrumb {
        display: none;
    }

    .before-shop-loop {
        @include flex(center,space-between);
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 30px;

        @include responsive($tabletSmall) {
            margin-bottom: get-vw(30);
        }

        .woocommerce-notices-wrapper {
            display: none;
        }

        .woocommerce-result-count {
            display: none;
        }

        .results {
            color: $green2;
            font-weight: 500;

            @include responsive($tabletSmall,max) {
                padding-left: space(2)
            }
        }

        .woocommerce-ordering {
            position: relative;

            .orderby {
                border: 1px solid $grey-l;
                border-radius: 100px;
                padding: 14px 24px;
                font-weight: 500;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 60px;
            }

            &::after {
                content: url('../../dist/imgs/SVG/filter.svg');
                position: absolute;
                right: 24px;
                top: 50%;
                pointer-events: none;
                transform: translateY(-50%);
            }
        }
    }

    ul.page-numbers {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;

        li {
            display: inline;
        }
    }
}
