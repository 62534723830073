.redirection__contact {
    background-color: $green1;
    color: $white;
    margin: space(0.5);
    border-radius: 25px;
    position: relative;

    @include responsive($tabletSmall, max) {
      padding: 0
    }

    .container {
        padding: 25px 10px 60px;

        @include responsive($tabletSmall) {
            @include flex(stretch,space-between);
            gap: 40px;
            flex-direction: row-reverse;
            padding: space(1.5) space(5);
        }

        .image {
            position: relative;
            width: 100%;
            
            @include responsive($tabletSmall, max) {
                margin-bottom: 75px;
            }

            @include responsive($tabletSmall) {
                max-width: space(18);
            }

            .rounded {
                @include imgRatio(558, 575);

                @include responsive($tabletSmall, max) {
                    min-height: 45dvh;
                }

                .visual__container {
                    @include cover();
                    overflow: visible;
                    border-radius: 50% 50% 15px 15px;  

                    img {
                        border-radius: 50% 50% 15px 15px;  
                    }
                }
            }
        }

        .text {
            @include responsive($tabletSmall, max) {
                padding: 0 20px;
            }

            @include responsive($tabletSmall) {
                max-width: space(16);
            }

            p {
                margin: 5px 0;
            }

            ul {
                padding-left: 1.9vw;
                margin: 5px 0;

                li {
                    margin: 0;
                    font-weight: 500;
    
                    @include responsive($tabletSmall) {
                        @include font-vw(16);
                    }

                    @include responsive($deskXL){
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .vector {
        position: absolute;
        width: 100%;

        .visual__container {
            @include cover();
            overflow: visible;

            .visual img {
                object-fit: initial;
            }
        }

        &.--red {
            left: space(-0.6);
            top: 5%;
            @include imgRatio(141, 130);
            max-width: 92px;

            @include responsive($tabletSmall) {
                max-width: get-vw(141);
            }
        }

        &.--magenta {
            left: space(2);
            bottom: -15%;
            @include imgRatio(126, 152);
            max-width: 65px;

            @include responsive($tabletSmall) {
                left: -10%;
                bottom: -3%;
                max-width: get-vw(126);
            }
        }

        &.--green {
            right: space(2);
            bottom: -8%;
            @include imgRatio(98, 105);
            max-width: 75px;

            @include responsive($tabletSmall) {
                right: -10%;
                top: 25%;
                bottom: initial;
                max-width: get-vw(98);
            }
        }
    }
}

.redirection__conseil {
    background-color: $green2;
    color: $white;
    margin: space(0.5);
    padding: 60px 0 90px 0;
    border-radius: 25px;
    position: relative;
    overflow: hidden;

    @include responsive($tabletSmall) {
        padding: get-vw(120) 0 get-vw(180) 0;
    }

    .container {
        margin: auto;
        text-align: center;
        z-index: 1;

        @include responsive($tabletSmall) {
            max-width: space(28);
        }

        .Btn {
            margin: 40px auto 0;
            background-color: $green1;

            svg {
                path {
                    fill: $green2
                }
            }
        }
    }

    .visual__container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        @include imgRatio(1300, 300);
        max-width: 85vw;
    }
}