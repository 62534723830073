.ScrollTop{
    @include imgRatio(158, 161);
    position: relative;
    width: get-vw(158);
  
    @include responsive($tabletSmall, max){
      min-width: 100px;
      margin-bottom: 3em;
    }
  
    @include noTouchHover() {
      svg{
        > .circles {
          transform: scale(0.95) rotate(-90deg);
        }
      }
    }
  
    svg {
      @include cover();
  
      > .circles{
        transition: transform 550ms $easeInOutCirc;
        transform-origin: 50% 50%;
      }
    }
  }
  