/* Compte client */

.woocommerce {
    max-width: 1200px;
    margin: 0 auto;

    p {
        font-size: 1em;
    }

    form .form-row label {
        font-size: 16px;
    }

    input {
        all:revert;
        display:initial;
    }

    section {
        padding: 20px 0;
    }

    &-info {
        a {
            text-decoration: underline;
        }
    }

    &-shipping-totals {
        .pickup-location-address {
            @extend p;
            line-height: 1.3;
            font-weight: 700;
            font-size: 1em!important;
        }
    }

    &-shipping-destination {
        margin: 12px 0;
    }

    &-button.button, &-Button.button, button.button.wp-element-button, a.button {
        color: $white!important;
        background: $green2!important;
        border-radius: 60px!important;
        padding: 16px 30px;

        &.delete {
            background: $red!important;
        }
    }

    table.shop_table {
        max-width: 100%;
        background: $white;

        th, td {
            padding: 10px;
        }

        .cart_item {
            .variation {
                display: none;
            }
        }

        td.product-total {
            max-width: 200px;
        }

        .lpp-shipping-package-wrapper {
            max-width: 250px;
        }

        td.payment-method-actions {
            text-align: right;
        }
    }

    h3 {
        text-align: center;
        font-size: 2.6rem;
        line-height: 1;
        margin-bottom: 20px;
    }

    .woocommerce-billing-fields h3 {
        text-align: left!important;
    }

    p {
        a { text-decoration: underline; color: $green2; }
        strong { font-weight: bold; }
    }

    &-input-wrapper { width: 100%; }
    form {
        fieldset {
            margin: 40px 0;
        }

        .form-row {
            input.input-text,
            textarea {
                border: 1px solid rgba(7, 56, 54, 0.12);
                border-radius: 8px;
                padding: 15px;
            }
        }
    }

    .select2-container .selection {
        width: 100%;
        .select2-selection {
            @include flex(center, space-between);
            min-height: 40px;
        }

        .select2-selection__arrow {
            height: 40px;
        }
    }

    &-MyAccount-navigation{
        ul {
            @include flex(center, center);
            flex-wrap: wrap;
            margin: 0 auto;
            max-width: 840px;
            gap: 10px;

            @include responsive($tabletSmall) {
                gap: 20px;
            }

            li {
                display: block;
                margin: 0 10px;
            }

            a {
                display: block;
                color: $black;
                font-size: 1.3rem;
                line-height: 1.2;
                transition: all 240ms $ease;
                text-decoration: none!important;

                @include responsive($tabletSmall) {
                    @include font-vw(24);
                }

                @include responsive($deskXL){
                    font-size: 24px;
                }

                @include noTouchHover() {
                    opacity: 1;
                    color: $green2;
                }
            }

            .is-active a {
                color: $green2;
            }

            .woocommerce-MyAccount-navigation-link--customer-logout a {
                opacity: 0.4;
            }
        }
    }

    &-MyAccount-content {
        border-top: 1px solid $beige;
        padding-top: 40px;
        max-width: 1000px;
        margin: 60px auto 0;

        > p {
            text-align: center;
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &-Addresses {
        margin-top: 40px;
    }

    &-Address {

        &-title {
            @include flex(flex-start, space-between);
            margin-bottom: 30px;

            h3 {
                text-align: left!important;
                margin-bottom: 0!important;
                max-width: 75%;
            }

            .edit {
                text-decoration: underline;
                color: $green2;
            }
        }

        address {
            background: $white;
            border-radius: 16px;
            padding: 30px;
        }
    }

    &-order-details,
    &-customer-details {
        padding: 60px 0 0;

        h2 {
            font-size: 3.2rem;
            margin-bottom: 40px;
        }

        address {
            background: $white;
            border-radius: 16px;
            padding: 30px!important;
        }
    }

    &-ResetPassword {
        text-align: center;
        max-width: 640px;
        margin: 0 auto;

        .woocommerce-form-row {
            width: 100%!important;
            max-width: 420px;
            margin: 40px auto!important;
            float: none!important;


            label {
                text-align: left!important;
            }
        }
    }

    #review {
        h3 {
            text-align: left;
        }

        .actions {
            margin-top: -20px;
            margin-bottom: 10px;
            text-align: right;
            color: $green1;

            a {
                font-size: 12px;
                text-decoration: underline;
            }
        }
    }
}

.Account {
    background: $peach;
    min-height: 100dvh;

    .Page__header {
        padding-bottom: 0;
    }

    .Page__content {
        padding-bottom: 30px;;
    }
}

#customer_login {

    .woocommerce-form {
        background: $white;
        border: $beige;
        padding: 30px;

        @include responsive($tabletSmall) {
            padding: space(1);
        }

        .woocommerce-form-login__rememberme {
            display: block;
        }
        .woocommerce-form-register__submit,
        .woocommerce-form-login__submit {
            margin-left: auto;
            float: right;
        }
    }

    h2 {
        font-family: $main-font;
        font-size: 20px;
        font-weight: 600;
        margin: 20px 0 0 0
    }

    a {
        color: $green1;
        transition: color 0.2s ease-in-out;

        &:hover {
            color: $green2;
        }
    }

    .woocommerce-Button {
        margin-top: 24px;
    }

    .show-password-input {
        position: absolute;
        right: 1.5em;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

.woocommerce-checkout-review-order {
    max-width: 820px;
    margin: 0 auto;
}

.select2-container--default .select2-results>.select2-results__options {
    overscroll-behavior: contain;
}

.woocommerce-shipping-methods select {
    all: revert;
    border: 1px solid $beige-d;
    padding: 8px 10px;
    border-radius: 4px;
    margin: 2px 0;
}
