.blog__header {
    background-color: $green2;
    color: $white;
    padding: 120px 0 90px 0;
    position: relative;
    overflow: hidden;

    @include responsive($tabletSmall) {
        padding: space(6) 0 get-vw(250) 0;
    }

    .container {
        margin: auto;
        text-align: center;
        z-index: 1;

        @include responsive($tabletSmall) {
            max-width: space(40);
        }

        span {
            color: $red;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 11px;
        }
    }

    .flowers {
        @include cover();
        @include flex(flex-end, center);
        padding: 0 space(4);
    }
}

.blog__list,
.single-post__related {
    padding-top: 40px;

    @include responsive($tabletSmall) {
        padding-top: get-vw(40);
    }

    .before-list {
        font-weight: 500;
        @include flex(center,space-between);
        gap: 20px;
        flex-wrap: wrap;

        @include responsive($tabletSmall) {
          @include font-vw(16);
        }

        @include responsive($deskXL){
            font-size: 16px;
        }

        span {
            color: $green2;
        }

        .filter {
            @include flex(center,initial);
            gap: 16px;
            flex-wrap: wrap;

            form {
                position: relative;

                @include responsive($tabletSmall, max) {
                    min-width: 100%;
                }
    
                &::after {
                    content: url(../../dist/imgs/SVG/filter.svg);
                    position: absolute;
                    right: 24px;
                    top: 50%;
                    pointer-events: none;
                    transform: translateY(-50%);
                }
    
                select {
                    border: 1px solid rgba(7, 56, 54, 0.12);
                    border-radius: 100px;
                    padding: 14px 24px;
                    min-width: 290px;
                    width: 100%;

                    @include responsive($tabletSmall) {
                        min-width: get-vw(290);
                    }
                }
            }   
        }
    }

    .list {
        display: grid;
        grid-template-columns: 100%;
        gap: 30px;
        margin-top: 40px;
    
        @include responsive($tabletSmall) {
            gap: 48px 30px;
            grid-template-columns: repeat(2, 1fr);
        }
    
        @include responsive($tablet) {
            grid-template-columns: repeat(3, 1fr);
            margin-top: get-vw(60);
        }

        .intro {
            background-color: $green1;
            border-radius: 15px;
            position: relative;
            padding: 30px 20px 120px;

            @include responsive($tabletSmall) {
                grid-area: 1 / 1 / 2 / 3;
                padding: get-vw(70);
            }

            .wswyg--content {
                color: #A3B9B8;
                font-weight: 500;
                font-size: 16px;
                position: relative;
                z-index: 1;

                @include responsive($tabletSmall) {
                    max-width: 60%;
                    @include font-vw(16);
                }

                @include responsive($deskXL){
                    font-size: 16px;
                }

                strong {
                    color: $red;
                    font-family: $title-font;
                    font-size: 15px;
                    line-height: 1;

                    @include responsive($tabletSmall) {
                      @include font-vw(24);
                    }

                    @include responsive($deskXL){
                        font-size: 24px;
                    }
                }

                h2 {
                    color: $white;
                    text-align: left;
                    font-size: 34px;
                    line-height: 0.9;
                    margin: 10px 0 30px;

                    @include responsive($tabletSmall) {
                      @include font-vw(54);
                      max-width: 80%;
                      margin: get-vw(10) 0 get-vw(30);
                    }

                    @include responsive($deskXL){
                        font-size: 54px;
                    }
                }
            }

            .vector {
                position: absolute;
                bottom: 0;
                right: 0;
                max-width: space(13);
            }
        }
    }

    .Btn {
        margin: 72px auto;

        @include responsive($tabletSmall) {
            margin: get-vw(70) auto;
        }
    }

    #hiddenPosts.visible {
        display: grid;
        grid-template-columns: 100%;
        gap: 30px;
        margin-top: 40px;
    
        @include responsive($tabletSmall) {
            gap: 48px 30px;
            grid-template-columns: repeat(2, 1fr);
        }
    
        @include responsive($tablet) {
            grid-template-columns: repeat(3, 1fr);
            margin-top: get-vw(48);
        }
    }

    #loadMorePosts.hidden {
        display: none;
    }
}

.single-post__related {
    padding: 80px 0;

    @include responsive($tabletSmall) {
        padding: get-vw(120) 0;
    }
}

.blog__products {
    padding: 0;

    .other-products {
        background: $peach-d1;
    }
}