.single__product {

    .product-type-variable .entry-text .summary > .price { display: none; }

    .single-product-details {
        background: $peach-d2;
        padding-bottom: 30px;
        
        @include responsive($tabletSmall) {
            @include flex(stretch, space-between);
            padding: 0;
        }

        .entry-images {
            position: relative;
            flex-basis: 50%;
            mix-blend-mode: darken;
            overflow: hidden;
            height: 50vh;
            min-height: 380px;

            @include responsive($tabletSmall) {
                height: 100vh;
                position: sticky;
                top: 0;
            }
    
            .swiper {
                @include cover();

                &.--dragging .swiper-slide {
                    transform: none!important;
                }

                .swiper-pagination {
                    position: absolute!important;
                    bottom: space(1)!important;
                    width: 100%;
                    justify-content: center;

                    @include responsive($tabletSmall) {
                        display: none;
                    }
                }

                .swiper-navigation {
                    mix-blend-mode: darken;
                    z-index: 10;
                    bottom: 0;
                    position: absolute!important;
                    width: 100%;
                    justify-content: center;
                    margin: 0;

                    @include responsive($tabletSmall) {
                        justify-content: flex-end;
                        bottom: space(1)!important;
                    }

                    @include responsive($tabletSmall, max) {
                        display: none;
                    }

                    .next svg {
                        transform: rotate(180deg);
                    }
                }

                .swiper-slide {
                    img {
                        @include cover();
                        object-fit:contain;
                    }
                }
            }

            /* img {
                width: 100%;

                @include responsive($tabletSmall) {
                    height: 100%;
                    object-fit: cover;
                }
            }

            .onsale {
                display: none;
            }*/
        }

        .entry-text {
            flex-basis: 50%;
            padding: space(4) space(2);
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include responsive($tabletSmall) {
                padding: get-vw(150) space(3);
            }

            .summary {
                @include flex(flex-start, flex-start);
                flex-wrap: wrap;
                flex-direction: column;

                > * {
                    width : 100%;
                }

                h1 {
                    order: 2;
                    font-size: 40px;
                    line-height: 1;
                    color: $green2;
                    margin: 0 0 12px 0;
    
                    @include responsive($tabletSmall) {
                      @include font-vw(50);
                      font-size: 50px;
                      margin: get-vw(35) 0;
                    }

                    @include responsive($deskXL){
                        font-size: 50px;
                    }
                }
    
                .price {
                    order: 1;
                    @extend h1;
                    color: $red;
                    margin: 0;
    
                    @include responsive($tabletSmall) {
                      @include font-vw(50);
                      font-size: 50px;
                    }

                    @include responsive($deskXL){
                        font-size: 50px;
                    }

                    del {
                        margin-right: 5px;
                        opacity: 0.7;
                        font-size: 40px;
        
                        .amount {
                            text-decoration:line-through
                        }

                        @include responsive($tabletSmall) {
                          @include font-vw(40);
                        }

                        @include responsive($deskXL){
                            font-size: 40px;
                        }
                    }
                }

                .woocommerce-product-details__short-description {
                    margin-top: 26px;
                    color: #767676;
                    font-size: 18px;
                    order: 4;

                    @include responsive($tabletSmall) {
                        margin-top: get-vw(35);
                        @include font-vw(18);
                        max-width: space(12);
                    }

                    @include responsive($deskXL){
                        font-size: 35px;
                    }
                }
    
                form.cart {
                    @include flex();
                    flex-wrap: wrap;
                    gap: 10px;
                    order: 3;

                    &.variations_form {
                        flex-direction: column;
                    }

                    .quantity {
                        .screen-reader-text {
                            display: none;
                        }

                        input {
                            border: 1px solid $grey-l;
                            border-radius: 66px;
                            padding: get-vw(20) get-vw(15);
                            text-align: center;
                            font-weight: 500;
                            font-size: 17px;
                            max-width: 89px;
                            height: 100%; 
                            width: 100%;

                            @include responsive($tabletSmall) {
                              @include font-vw(17);
                              max-width: space(3);
                            }

                            @include responsive($deskXL){
                                font-size: 18px;
                            }
                        }
                    }
        
                    button {
                        background: $red;
                        border-radius: 60px;
                        color: $white;
                        padding: 20px 30px;
                        font-size: 17px;
                        font-weight: 500;
                        line-height: 1.2;
                        @include flex(center,center);
                        gap: 10px;

                        @include responsive($tabletSmall) {
                          @include font-vw(17);
                          padding: get-vw(20) get-vw(30);
                        }

                        @include responsive($deskXL){
                            font-size: 17px;
                        }

                        &:hover {
                            &::after {
                                transform-origin: 50% 45%;
                                transform: rotate(180deg);
                            }
                        }

                        &::after {
                            content: url('../../dist/imgs/SVG/icon-btn.svg');
                            transition: all .3s ease-in-out
                        }
                    }

                    .variations_button {
                        @include flex(stretch,initial);
                        gap: 10px;
                    }

                    .variations {
                        width: 100%;
                        color: $green2;

                        @include responsive($tabletSmall) {
                            max-width: 400px;
                        }

                        tr {
                            display: block;
                        }

                        .label {
                            display: block;
                            min-width: 100%;
                           white-space: nowrap;
                           padding-right: 20px;
                           color: $base;
                           font-weight: 600;
                           margin-bottom: 6px;

                           @include responsive($tabletSmall) {
                               margin-bottom: get-vw(6);
                           }
                        }

                        tr {
                            margin-bottom: 10px;

                            @include responsive($tabletSmall) {
                                margin-bottom: get-vw(10);
                            }
                        }

                        .value {
                            display: block;
                            position: relative;
                            min-width: 100%;

                            &::after {
                                content: '';
                                display: block;
                                width: 12px;
                                background: url('../../dist/imgs/SVG/down.svg') no-repeat center center;
                                background-size: contain;
                                position: absolute;
                                right: 30px;
                                top: 0;
                                bottom: 0;
                                pointer-events: none;

                                @include responsive($tabletSmall) {
                                    right: get-vw(30);
                                    width: get-vw(12);
                                }
                            }
                        }

                        select {
                            border: 1px solid rgba(7, 56, 54, 0.12);
                            border-radius: 66px;
                            padding: 21px 30px;
                            width: 100%;

                            @include responsive($tabletSmall) {
                                padding: get-vw(21) get-vw(30);
                                border-radius: get-vw(66);
                            }
                        }
                    }

                    .single_variation_wrap .woocommerce-variation-price {
                        margin: 30px 0 30px;

                        @include responsive($tabletSmall) {
                          margin: get-vw(30) 0 get-vw(30);
                        }
                    }
                }
            }

            .woocommerce-notices-wrapper {
                margin-top: 30px;
                text-align: left;

                @include responsive($tabletSmall) {
                    margin-top: get-vw(30);
                }
            }

            .woocommerce-message {
                padding: 0;
                font-weight: 600;
                color: $green2;
              
                .wc-forward {
                    display: none;
                }
            }

            .out-of-stock,
            .woocommerce-error {
                @extend .woocommerce-message;
                color: $red;

                a {
                    color: $red;
                    text-decoration: underline;
                }
            }

            .product_meta, 
            section.related.products,
            .wc-tabs-wrapper,
            .upsells,
            .reset_variations {
                display: none!important;
            }
        }

        .woocommerce-notices-wrapper .woocommerce-message {
            top: 80px;
            right: 20px;
            width: calc(100% - 40px);
            max-width: 280px;
            position: absolute;
            background: $red;
            color: $white;
            padding: 12px 20px;
            border-radius: 10px;
            line-height: 1.1;
            text-align: left;
            @include flex(flex-start, flex-start);
            flex-wrap: wrap;

            @include responsive($tabletSmall) {
                top: space(3);
                right: space(1);
                max-width: get-vw(280);
                border-radius: get-vw(10);
                padding: space(0.5) space(1);
            }

            .button.wc-forward {
                display: inline-block;
                float:none;
                order: 2;
                margin-top: 10px;
                padding: 10px 20px;
                background: $white;
                color: $red;
                border-radius: 40px;
                font-size: 13px;
            }
        }
    }
}

.single__related {
    padding: 70px space(2);

    @include responsive($tabletSmall) {
        padding: get-vw(100) space(2) ;
    }

    h2 {
        @include responsive($tabletSmall) {
            max-width: space(24);
        }
    }
}

.product_cat-abonnement {
    &.single-product-details {
        justify-content: initial;

        .entry-text {
    
            .quantity {
                display: none;
            }

            button {
                width: 100%;
            }
        }
    }
}

.product-type-variable {
    .entry-text .summary .price {
        font-size: 20px!important;

        @include responsive($tabletSmall) {
          @include font-vw(25);
        }

        @include responsive($deskXL){
            font-size: 25px;
        }
    }

    .woocommerce-variation-price .price span {
        font-size: 50px;

        @include responsive($tabletSmall) {
            @include font-vw(50);
        }

        @include responsive($deskXL){
            font-size: 50px;
        }
    }
}