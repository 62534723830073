@font-face {
  font-family: avenir;
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../fonts/Metropolis-Regular.otf') format("otf");
}

@font-face {
  font-family: gulax;
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url('../fonts/gulax-webfont.woff') format("woff");
}