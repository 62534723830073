.Footer {
   position: relative;
   z-index: 100;
   overflow: hidden;
   padding: 60px 0 0;

   @include responsive($tabletSmall){
      padding: get-vw(120) 0 0;
    }

    > .container{
      position: relative;
      z-index: 10;
      @include flex(flex-start);

      @include responsive($tabletSmall, max){
        flex-direction: column;
      }

      > .wrapper{
         width: 100%;
   
         @include responsive($tabletSmall){
            max-width: space(23);
            margin-left: auto;
         }
   
         .primary{
            @include flex(flex-start);
            gap: space(3);
   
            @include responsive($tabletSmall, max){
               flex-direction: column;
               align-items: center;
               gap: 40px;
            }
         }
      }
   }

   canvas {
      pointer-events: none;
      @include cover();
      width: 100%!important;
      height: 100%!important;
   }
}
