.CopyrightWokine {
  @include flex(center, center);

  .wkn {
     @include imgRatio(72, 9);
     position: relative;
     margin-left: 0.35em;
     width: get-vw(72);

     @include responsive($tabletSmall, max){
       min-width: 72px;
     }
     
     svg {
       @include cover();

       .Footer & {
         fill: #AEA39D;
       }
     }
  }
}
