.Link {
  display: inline-block;
  font: inherit;
  position: relative;

  @include noTouchHover() {
    span{
      background-size: 100% 1px;
      background-position: 0% 100%;
    }
  }

  span{
    display: inline-block;
    background-image: linear-gradient($black, $black);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    transition: background-size 600ms $easeInOutCirc;
  }

  &.--min {
    font-size: 16px;
    font-weight: 500;
  }
}