.recruit__block {
    position: relative;
    margin: 15px;
    padding: 0;
    overflow: hidden;

    .container {
        background: $red;
        color: $white;
        padding: 120px 20px;
        text-align: center;
        border-radius: 25px;
        font-weight: 500;

        @include responsive($tabletSmall) {
            padding: get-vw(120) get-vw(20);
        }

        p {
            @include responsive($tabletSmall) {
                max-width: space(17);
                margin: auto;
            }
        }

        .Btn {
            background: $white;
            color: $red;
            margin: 40px auto 0;

            @include responsive($tabletSmall) {
                margin: get-vw(40) auto 0;
                @include font-vw(17);
            }

            @include responsive($deskXL){
                font-size: 17px;
            }

            svg {
                path {
                    fill: $red
                }
            }
        }
    }

    .vector {
        position: absolute;
        width: 100%;
        right: 0;
        bottom: 0;
        @include imgRatio(437, 449);
        max-width: 130px;

        @include responsive($tabletSmall) {
            max-width: get-vw(437);
        }

        .visual__container {
            @include cover();
            overflow: visible;

            img {
                object-fit: contain;
            }
        }
    }
  
}