.Brand {
  display: block;
  position: relative;
  z-index: 10;
  pointer-events: all;
  margin-right: auto;
  overflow:hidden;

  @include responsive($tabletSmall, max) {
    max-width: space(10);

    svg {
      width: 100%;
      max-width: 75px;
    }
  }

  .wkn {
    display: block;
  }

  body.showMenu & svg {
    .letter {
      fill: $white
    }
  }

  .Intro & {
    margin-left: auto;

    svg > * {
      fill: $white;
    }
  }
}