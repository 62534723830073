@import url("../../node_modules/swiper/swiper-bundle.min.css");

.swiper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;

  &-wrapper {
    order: 2;
    cursor: grab;

    .swiper-container:not(.swiper-container-free-mode) &{
      transition: transform 600ms $ease!important;
    }

    .swiper-container.--dragging & {
      transition: none!important;
    }
  }

  &-slide {
    height: auto;
    transition: transform 600ms $ease!important;

    .swiper:not(.swiper-creative).--dragging & {
      transform: scale(0.95)!important;
    }
  }

  &-navigation {
    order: 1;
    margin-bottom: 20px;
    @include flex(center, flex-end);

    > * {
      cursor: pointer;
      transition: opacity 240ms $easeOutQuad;

      &.swiper-button-disabled {
        opacity: 0.2;
        pointer-events: none;
      }

      &+* {
        margin-left: 20px;
      }
    }
  }

  &-pagination {
    order: 3;
    position: relative!important;
    margin-top: 20px!important;
    bottom: 0px!important;
    @include flex(center, flex-end);
    gap: 6px;

    @include responsive($tabletSmall) {
      gap: get-vw(7);
    }

    > * {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 10px;
      background: $base;
      cursor: pointer;
      transition: all 240ms $easeOutQuad;
      margin: 0!important;

      @include responsive($tabletSmall) {
        width: get-vw(7);
        height: get-vw(7);
      }

      .--dark & {
        background: $grey-l;
      }

      @include noTouchHover() {
        transform: scale(1.1);
      }

      &.--active {
        background: $links-color;
      }
    }
  }

  &-scrollbar {
    order: 4;
    position: relative!important;
    left: 0%!important;
    width: 100%!important;
    margin-top: 20px;
    height: 2px!important;
    background: $grey-l;

    .--dark & {
      background: $base-d;
    }

    &-drag {
      position: absolute;
      top: 0px;
      background: $base;
      border-radius: 4px;
      cursor: pointer;

      .--dark & {
        background: $grey-l;
      }
    }
  }
}
