.reinsurance {
    padding: 0;
    background: $peach-d1;

    &__cards {
        position: relative;

        .intro {
            height: 300vh;

            .sticky {
                position: sticky;
                top: 0;
                padding: 70px 60px;
                min-height: 100vh;
                @include flex(center, center);

                @include responsive($tabletSmall) {
                    padding: get-vw(70) 0;
                }
            }
        }

        .container {
            text-align: center;

            @include responsive($tabletSmall) {
                max-width: space(26);
            }

            h2 {
                font-size: 45px;
                line-height: 85%;
                margin-bottom: 20px;

                @include responsive($tabletSmall) {
                  @include font-vw(70);
                  margin-bottom: get-vw(20);
                }

                @include responsive($deskXL){
                    font-size: 70px;
                }
            }

            p {
                font-size: 16px;

                @include responsive($tabletSmall) {
                  @include font-vw(24);
                  max-width: space(18);
                  margin: auto;
                }

                @include responsive($deskXL){
                    font-size: 24px;
                }
            }
        }
    }

    &__history {
        padding: 65px 0;
        text-align: center;
        position: relative;

        @include responsive($tabletSmall) {
          padding: get-vw(160) 0;
        }

        .text {
            .visual__container {
                @include imgRatio(1312, 530);
            }
        }

        p {
            margin: 60px auto 40px;

            @include responsive($tabletSmall) {
                margin: get-vw(100) auto get-vw(40);
                max-width: space(15);
            }
        }

        .Btn {
            margin: auto;
        }

        .vector {
            position: absolute;
            width: 100%;

            .visual__container {
                @include cover();
                overflow: visible;

                img {
                    object-fit: contain;
                }
            }

            &.--green {
                right: space(6);
                top: space(6);
                @include imgRatio(141, 130);
                max-width: 39px;

                @include responsive($tabletSmall) {
                    max-width: get-vw(141);
                }
            }

            &.--magenta {
                right: 50%;
                top: 40%;
                @include imgRatio(150, 136);
                max-width: 41px;

                @include responsive($tabletSmall) {
                    max-width: get-vw(150);
                }
            }

            &.--line {
                left: space(10);
                top: 37%;
                @include imgRatio(264, 142);
                max-width: get-vw(264);
            }
        }
    }
}