.faq {
    padding: 0;

    .faq__container {
        padding: 50px space(3);

        @include responsive($tabletSmall) {
            @include flex(initial,space-between);
            gap: space(4);
            padding: get-vw(135) space(4);
        }
    }

    aside {
        flex-basis: 24%;
        text-transform: uppercase;
        color: #A1BBBA;

        @include responsive($tabletSmall, max) {
            margin-bottom: 60px;
        }

        ul {
            @include responsive($tabletSmall) {
                position: sticky;
                top: 80px;
            }

            @include responsive($tabletSmall, max) {
                @include flex(center, center);
                flex-wrap: wrap;
                gap: 10px;
            }
        }

        li {
            @include responsive($tabletSmall, max) {
                margin: 0 10px;
            }

            &+li {
                @include responsive($tabletSmall) {
                    margin-top: get-vw(10);
                }
            }

            .link {
                font-size: 17px;
                font-weight: 900;
                transition: color 0.15s ease-in-out;
                @include flex(center,flex-end);
                gap: 6px;
    
                @include responsive($tabletSmall) {
                  @include font-vw(17);
                }

                @include responsive($deskXL){
                    font-size: 17px;
                }

                &::after {
                    @include responsive($tabletSmall) {
                        content: '';
                        background: $red;
                        width: 0;
                        height: 2px;
                        transition: width 0.2s ease-in-out;
                    }
                }
    
                &.--active, &:hover {
                    color: $red;

                    &::after {
                        @include responsive($tabletSmall) {
                            width: get-vw(16);
                        }
                    }
                }
            }
        }
    }

    main {
        flex-basis: 77%;

        h2 {
            margin-bottom: 20px;
            font-size: 35px;

            @include responsive($tabletSmall) {
                text-align: left;
                margin-bottom: get-vw(36);
                @include font-vw(70);
            }

            @include responsive($deskXL){
                font-size: 70px;
            }
        }
    }

    &__theme {
        &:not(:last-child) {
            margin-bottom: 60px;

            @include responsive($tabletSmall) {
                margin-bottom: get-vw(80);
            }
        }
    }

    &__block {
        border-radius: 15px;
        border: 1px solid rgba(7, 56, 54, 0.12);

        &:last-child {
            margin: 0;
        }

        .card {
            border-bottom: 1px solid rgba(7, 56, 54, 0.12);
            transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;

            &:first-child {
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
            }

            &:last-child {
                border: 0;
                border-bottom-right-radius: 15px;
                border-bottom-left-radius: 15px;
            }

            &:not(.--active):hover {
                background-color: $green2;
                color: $white;

                .faq-more {
                    .circle {
                        path {
                            stroke: $green1
                        }
                    }

                    .square {
                        path {
                            stroke: $white
                        }
                    }
                }
            }

            &.--active {
                background-color: $peach;

                h4 {
                    color: $green2;
                }

                svg {
                    path {
                        fill: $green2!important;
                        stroke: $green2;
                    }

                    &.circle {
                        transform: rotate(90deg);
                    }

                    path.less {
                        stroke: $white
                    }
                }

                .card__content {
                    height: auto;
                    padding: 0 25px 25px 25px;
    
                    @include responsive($tabletSmall) {
                        padding: 0 get-vw(40) get-vw(40) get-vw(40);
                    }

                    p {
                        transform: translateY(0px);

                        a {
                            color: $green2;
                            text-decoration: underline;
                        }
                    }
                }
            }

            h4 {
                font-weight: 600;
                font-size: 18px;
                margin: 0;
                @include flex(center,space-between);
                gap: 20px;
                position: relative;
                padding: 25px;
                cursor: pointer;

                @include responsive($tabletSmall) {
                  @include font-vw(22);
                  padding: get-vw(40);
                }

                @include responsive($deskXL){
                    font-size: 22px;
                }

                .faq-more {
                    position: relative;

                    path {
                        transition: stroke 0.1s ease-in-out;
                        fill: none;
                    }

                    svg.circle {
                        transition: transform 420ms $ease;
                    }

                    .square {
                        position: absolute;
                        top: 33%;
                        left: 31%;
                    }
                }
            }

            &__content {
                overflow: hidden;
                height: 0;

                p {
                    transition: transform 420ms $ease;
                    transform: translateY(40px);
                }
            }
        }
    }

    &__contact {
        background: $magenta;
        color: $white;
        margin: 15px;
        border-radius: 25px;
        padding: 115px 0;
        text-align: center;
        position: relative;
        overflow: hidden;

        @include responsive($tabletSmall) {
            padding: get-vw(115) 0;
        }

        h3 {
            margin: 0 auto 40px;

            @include responsive($tabletSmall) {
                max-width: space(20);
                margin: 0 auto get-vw(40);
            }
        }

        .Btn {
            background: $green1;
            margin: auto;

            svg {
                path {
                    fill: $red;
                }
            }
        }

        .vector {
            position: absolute;
            width: 100%;

            .visual__container {
                @include cover();
                overflow: visible;
            }

            &.--red {
                right: -2%;
                top: 15%;
                @include imgRatio(69, 81);
                max-width: 39px;

                @include responsive($tabletSmall) {
                    right: space(7);
                    top: 40%;
                    max-width: get-vw(69);
                }
            }

            &.--question {
                left: space(2);
                bottom: 0;
                @include imgRatio(370, 453);
                max-width: 100px;

                @include responsive($tabletSmall) {
                    max-width: get-vw(370);
                }
            }

            &.--green {
                right: -2%;
                bottom: 10%;
                @include imgRatio(98, 105);
                max-width: 75px;

                @include responsive($tabletSmall) {
                    max-width: get-vw(98);
                }
            }
        }
    }

    .other-products {
        background: $peach-d1;
    }
}