h1, h2, h3, h4, h5, h6{
   font-weight: 500;
   font-family: $title-font;
}

h1 {
   line-height: 1.05;
   margin-bottom: 0;
   font-size: 10vw;

   @include responsive($tabletSmall){
      @include font-vw(90);
   }

   @include responsive($deskXL){
      font-size: 90px;
   }
}

h2 {
   text-align: center;
   font-size: 45px;
   line-height: 1.15;

   @include responsive($tabletSmall) {
       @include font-vw(70);
       margin: 0 auto get-vw(75) auto;
   }

   @include responsive($deskXL){
      font-size: 70px;
   }
}

h3 {
   font: 400 45px $title-font;
   margin-bottom: 30px;

   @include responsive($tabletSmall){
     @include font-vw(70);
     margin-bottom: get-vw(30);
   }

   @include responsive($deskXL){
      font-size: 70px;
   }
}

h4 {
   font: 400 22px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(22);
   }

   @include responsive($deskXL){
      font-size: 22px;
   }
}

h5 {
   font: 400 20px $main-font;

   @include responsive($tabletSmall){
     @include font-vw(20);
   }

   @include responsive($deskXL){
      font-size: 20px;
   }
}

h6 {
   font: 400 18px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(18);
   }

   @include responsive($deskXL){
      font-size: 18px;
   }
}

.subtitle {
   font: 700 11px $main-font;
   letter-spacing: 0.1em;
   text-transform: uppercase;
   display: block;

   @include responsive($tabletSmall) {
      @include font-vw(11);
   }

   @include responsive($deskXL){
      font-size: 11px;
   }
}

strong {
   font-weight: 500;
}

b {
   font-weight: 700;
}

em {
   font-style: italic;
}

p {
   font-size: 16px;
   line-height: 1.6;
   font-weight: 500;

   @include responsive($tabletSmall){
     @include font-vw(16);
   }

   @include responsive($deskXL){
      font-size: 16px;
   }

   &.no__results {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      opacity: 0.2;

      @include responsive($tabletSmall){
        @include font-vw(32);
      }

      @include responsive($deskXL){
          font-size: 32px;
      }
   }
}

.wswyg--content {
   text-align: left;

   * { color: inherit }

   > * + * {
      margin-top: 24px;

      @include responsive($tabletSmall){
         margin-top: get-vw(24);
      }
   }

   ul,
   ol {
      @extend p;
      padding-left: 30px;

      @include responsive($tabletSmall){
         padding-left: space(2);
      }

      > li + li {
         margin-top: 1em;
      }

      code {
         margin-top: 1em;
         margin-bottom: 1em;
      }
   }

   ul{
      list-style: disc;
   }

   ol {
      list-style-type: decimal;
   }

   figure {
      margin: 40px auto;
      overflow: hidden;

      @include responsive($tabletSmall){
         margin: space(2) auto;
      }

      &:last-child {
         margin-bottom: 0px;
      }

      img {
         display: block;
      }
   }

   blockquote {
      padding-left: 30px;
      border-left: 2px solid $grey;

      @include responsive($tabletSmall){
         padding-left: space(1);
      }

      p{
         font-weight: 500;
         font-size: 28px;
         line-height: 1.4;

         @include responsive($tabletSmall){
            @include font-vw(32);
         }

         @include responsive($deskXL){
             font-size: 32px;
         }
      }
   }

   a {
      color: $green1;
      font-weight: bold;
   }
}
