.Icon {
  position: relative;
  @include imgRatio(1, 1);
  min-width: 24px;

  svg {
    @include cover();
    fill: $base;
  }

  &.--play {
    @include imgRatio(17, 20);
    width: get-vw(17);

    @include responsive($tabletSmall, max){
      min-width: 17px;
    }
  }

  &.--email{
    @include imgRatio(17, 14);
    width: get-vw(17);

    @include responsive($tabletSmall, max){
      min-width: 17px;
    }
  }

  &.--linkedin,
  &.--facebook,
  &.--twitter,
  &.--instagram{
    svg > *{
      fill: $green2;
    }
  }

  &.--linkedin{
    @include imgRatio(15, 14);
    width: get-vw(12);
    width: get-vw(12);
    min-width: 20px;

    @include responsive($tabletSmall, max){
      min-width: 12px;
    }
  }

  &.--facebook{
    @include imgRatio(32, 32);
    width: get-vw(32);

    @include responsive($tabletSmall, max){
      min-width: 32px;
    }
  }

  &.--twitter{
    @include imgRatio(18, 14);
    width: get-vw(18);

    @include responsive($tabletSmall, max){
      min-width: 32px;
    }
  }

  &.--instagram{
    @include imgRatio(32, 32);
    width: get-vw(32);

    @include responsive($tabletSmall, max){
      min-width: 32px;
    }
  }
}
