.Socials{
    .Footer &{
      @include responsive($tabletSmall, max){
        position: absolute;
        top: 0; right: 3vw;
      }
  
      @include responsive($tabletSmall){
        margin-left: auto;
      }
    }
  
    > ul{
      @include flex(center);
      gap: 0.75em;
    }
  }
  