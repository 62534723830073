.Form {
  background-color: $white;
  margin: auto;
  padding: 16px;
  border-radius: 25px;

  @include responsive($tabletSmall) {
    padding: get-vw(16);
    max-width: space(30);
  }

  .--error {
    color: $error-color!important;
  }

  .row {
    @include flex(flex-start, center);
    margin: auto;
    border: 3px solid #EBEBEB;
    border-radius: 25px;
    padding: get-vw(64);

    > * {
      width: 100%;

      &.--full {
        @include responsive($tabletSmall) {
          max-width: calc(100% - 30px);
          min-width: calc(100% - 30px);
          margin-right: 30px;
        }
      }
    }

    .bottom {
      margin-top: get-vw(40);

      @include responsive($tabletSmall) {
        @include flex(center,space-between);
        gap: 20px;
      }

      p {
        font-size: 12px;
        color: $green2;
        margin-bottom: 30px;

        @include responsive($tabletSmall) {
          max-width: space(11);
          margin-bottom: 0;
        }

        span {
          position: relative;

          a {
            font-weight: 600;
            color: $green1;
  
            &::after {
              content: '';
              width: 100%;
              height: 1px;
              background: $grey-l;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }

      &__confirmation {
        display: flex;
        gap: 9px
      }
    }

    .flex {
      @include responsive($tabletSmall) {
        @include flex(center,space-between);
        gap: 24px;
      }
    }
  }

  label:not(.Checkbox) {
    display: block;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 15px;
    font-weight: 900;
    letter-spacing: 0.1em;
    margin-bottom: 6px;
    padding: 0;
    color: $green2;
  }

  &__group {
    position: relative;
    width: 100%;
    margin-bottom: get-vw(24);

    // &:not(.--textarea):not(.--select) {
    //   label {
    //     position: absolute;
    //     top: 21px;
    //     left: 10px;
    //     opacity: 0.3;
    //     transform: translateY(-50%) scale(1);
    //     transition: all 400ms $easeOutExpo;
    //     transform-origin: left center;
    //     padding: 0px 10px;
    //   }

    //   &.--focused,
    //   &.--filled {
    //     label {
    //       position: absolute;
    //       left: 10px;
    //       opacity: 1;
    //       transform: translateY(-26px) scale(0.8);
    //     }
    //   }
    // }

    span.--error {
      position: absolute;
      top: 100%;
      left: 0;
      font-size: 10px;
      padding-top: 2px;
    }
  }

  &__control {
    width: 100%;
    min-height: 42px;
    padding: 0 14px;
    border-radius: 0;
    outline: 0;
    border: 1px solid $grey-l;
    border-radius: 15px;
    box-shadow: none;
    padding: 18px;

    @include responsive($tabletSmall) {
      padding: get-vw(24);
    }

    .--error & {
      border-color: $error-color;
    }

    &::placeholder {
      opacity: 0;
    }
  }

  select.Form__control {
    line-height: 42px;
  }

  textarea.Form__control {
    max-width: 100%;
    min-width: 100%;
    min-height: 200px;
    max-height: 200px;
    padding: 18px;

    @include responsive($tabletSmall) {
      padding: get-vw(24);
    }
  }

  &__actions {
    .Btn {
      margin-top: 0;
    }
  }

  &__output {
    color: $valid-color;

    p:not(:empty) {
      margin-top: 20px;
    }
  }
}

.Radios,
.Checkboxs {
  margin-bottom: -10px;

  @include responsive($tabletSmall) {
    @include flex(center, flex-start, inline-flex);
  }

  > * {
    margin-bottom: 10px;
    @include responsive($tabletSmall) {
      margin-right: 40px;
    }
  }
}

.Checkbox {
  position: relative;
  @include flex(flex-start, flex-start, inline-flex);
  display: flex !important;
  flex-wrap: nowrap;

  input {
    @include cover();
    margin: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;

    &:checked {
      & + .Checkbox__custom {
        background: $base;

        &:after {
          transform: scale(1);
        }

        svg {
          opacity: 1;
        }
      }
    }
  }

  &__custom {
    @include flex(center, center);
    min-width: 18px;
    max-width: 18px;
    height: 18px;
    border: 1px solid $base;
    border-radius: 2px;
    margin-right: 10px;
    transition: background 240ms $easeOutQuad;

    .--radio & {
      border-radius: 18px;

      &:after {
        @include content;
        width: 10px;
        height: 10px;
        background: $white;
        border-radius: 20px;
        transform: scale(0);
        transition: transform 240ms $easeOutQuad;
      }
    }

    .check {
      position: relative;
      width: 66%;

      &:before {
        @include content();
        padding-top: percentage(math.div(25, 33));
      }

      svg {
        overflow: visible;
        @include cover();
        opacity: 0;

        > * {
          fill: transparent;
          stroke: $white;
          stroke-width: 6px;
          stroke-linecap: round;
        }
      }
    }
  }

  &__label {
    text-transform: none;
    font-size: 13px;
    letter-spacing: 0;
    font-weight: 500;
  }
}
