.other-products {
    background: $peach;
    overflow: hidden;

    @include responsive($tabletSmall) {
      padding: get-vw(130) 0;
    }

    h2 {
        margin: 0 auto 30px;
        max-width: space(28);
        line-height: 90%;

        @include responsive($tabletSmall) {
            margin: 0 auto get-vw(30);
        }
    }

    .cta {
        text-align: center;
    }

    .swiper {
        max-width: 320px;
        margin: 40px auto;

        @include responsive($tabletSmall) {
            margin-top: get-vw(40);
            max-width: 100%;
        }
    }

    .--subscriptions & {
        background: $peach-d1;
    }
}