.FooterNavSub{
  @include flex(flex-start);
  flex-direction: column;
  align-items: center;
  font: 500 12px $main-font;
  color: #AEA39D;
  margin-top: 5em;

  @include responsive($tabletSmall){
    @include font-vw(12);
    align-items: flex-start;
  }

  @include responsive($deskXL){
      font-size: 12px;
  }

  nav > ul{
    @include flex(flex-start);
    flex-wrap: wrap;

    @include responsive($tabletSmall, max){
      align-items: center;
      justify-content: center;
      gap: 1em;
    }

    & + ul {
      margin-top: 0.5em;
      
      @include responsive($tabletSmall, max){
        margin-top: 1em;
      }
    }

    li{
      &:not(:first-child){
        @include flex(center);

        &:before{
          @include content();
          height: get-vw(2);
          width: get-vw(2);
          border-radius: 50%;
          background: #AEA39D;
          margin: 0 0.5em;

          @include responsive($tabletSmall, max){
            height: 2px;
            width: 2px;
          }
        }
      }
    }
  }

  nav > ul:first-child{
    li:last-child{
      @include responsive($tabletSmall, max){
        flex: 1 0 100%;
        max-width: 100%;
        justify-content: center;
        margin-top: 1em;

        &:before{
          content: none;
        }
      }
    }
  }

  .Link{
    span{
      background-image: linear-gradient(#c5b4ab, #c5b4ab);
    }
  }  
}
