.FooterNavBlock{

    @include responsive($tabletSmall, max){
      text-align: center;
    }
  
    .subtitle{
      margin-bottom: 2em;
    }

    ul {
      display: block;

      li {
        display: block;
  
        & + li{
          margin-top: 0.65em;
        }
      }
    }
  
    .Link{
      display: block;
      font: 500 15px $main-font;
      
      @include responsive($tabletSmall){
        @include font-vw(15);
      }

      @include responsive($deskXL){
          font-size: 15px;
      }
    }
  }
  