.subscriptions {
    .container {
        @include responsive($tabletSmall) {
            padding: space(2) space(7)
        }
    }

    &__title {
        margin-bottom: get-vw(100);

        @include responsive($tabletSmall) {
            @include flex(initial,space-between);
            gap: space(1);
        }

        &::before {
            content: url('../../dist/imgs/SVG/full-flower.svg');
            position: relative;
            top: -40px;

            @include responsive($tabletSmall,max) {
                display: none;
            }
        }

        h2 {
            text-align: left;
            margin: 0;
            flex-basis: 50%;

            @include responsive($tabletSmall, max) {
              margin-bottom: 25px;
            }
        }

        p {
            flex-basis: 45%;
        }
    }

    &__form {
        .attribut {
            background-color: $peach-d1;
            margin: 10px 0;
            border-radius: 15px;
            padding: 40px 30px;
            position: relative;
          
            @include responsive($tabletSmall) {
                @include flex(center,space-between);
                gap: 20px;
                padding: get-vw(80) get-vw(65);
            }

            &::after {
                content: url(../../dist/imgs/SVG/step.svg);
                position: absolute;
                left: 0;
                right: 0;
                bottom: -40px;
                margin: auto;
                max-width: max-content;
                z-index: 1;
            }

            h4 {
                font-weight: 800;
                font-size: 24px;
                margin: 0;

                @include responsive($tabletSmall) {
                    flex-basis: 29%;
                    @include font-vw(24);
                }

                @include responsive($deskXL){
                    font-size: 24px;
                }
            }

            .title {
                flex-basis: 29%;

                span {
                    opacity: 0.6;
                    margin-top: 9px;

                    @include responsive($tabletSmall) {
                        @include font-vw(16);
                    }

                    @include responsive($deskXL){
                        font-size: 16px;
                    }
                }
            }

            .termes {
                @include flex(stretch,initial);
                gap: get-vw(20);
                flex-basis: 67%;

                @include responsive($tabletSmall,max) {
                    flex-wrap: wrap;
                    margin-top: 24px;
                }

                .Checkbox.--radio {
                    position: relative;
                    font-family: $title-font;
                    padding: 30px 0;
                    text-align: center;
                    background-color: $peach;
                    border-radius: 10px;
                    min-width: space(4);
                    @include flex(center,center);

                    @include responsive($tabletSmall,max) {
                        max-width: 48%;
                        width: 100%;
                    }

                    @include responsive($tabletSmall) {
                        padding: get-vw(30) space(1);
                    }
    
                    input[type="radio"]:checked + .Checkbox__custom {
                        opacity: 1;
                    }
                    
                    .Checkbox__custom {
                        @include cover();
                        min-width: 0;
                        max-width: 100%;
                        z-index: 0;
                        border-radius: 10px;
                        background: $white;
                        border: 4px solid $green2;
                        opacity: 0;
                        pointer-events: none;
                        transition: opacity 240ms $ease;

                        @include responsive($tabletSmall) {
                            border-width: get-vw(4);
                            border-radius: get-vw(10);
                        }
                    }

                    .Checkbox__label {
                        position: relative;
                        z-index: 10;
                        font-size: 36px;
                        font-weight: 400;
                        line-height: 0.8;
                        display: flex;
                        align-items: center;
                        gap: 7px;
                        pointer-events: none;

                        @include responsive($tabletSmall) {
                            @include font-vw(36);
                        }

                        @include responsive($deskXL){
                            font-size: 36px;
                        }

                        span {
                            font-family: $main-font;
                            font-size: 16px;
                            color: $red;

                            @include responsive($tabletSmall) {
                                @include font-vw(16);
                            }

                            @include responsive($deskXL){
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            &.--taille {
                .Checkbox.--radio {
                    padding: 30px 0;

                    @include responsive($tabletSmall) {
                        padding: get-vw(35) get-vw(40);
                    }

                    &::after {
                        position: absolute;
                        top: -8%;
                        right: space(0.5)
                    }

                    &:nth-child(1) {
                        &::after {
                            content: url('../../dist/imgs/SVG/small-rounded-flower.svg');
                        }
                    }

                    &:nth-child(2) {
                        &::after {
                            content: url('../../dist/imgs/SVG/small-heart.svg');
                        }
                    }

                    &:nth-child(3) {
                        &::after {
                            content: url('../../dist/imgs/SVG/small-flower.svg');
                        }
                    }

                    &:nth-child(4) {
                        &::after {
                            content: url('../../dist/imgs/SVG/small-star.svg');
                        }
                    }

                    .Checkbox__label {
                        display: block;
                    }
                }
            }

            &.--type {
                .termes {
                    @include responsive($tabletSmall) {
                        flex-wrap: wrap;
                    }
                }

                .Checkbox.--radio {
                    padding: 20px 10px;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;

                    @include responsive($tabletSmall) {
                        max-width: space(9.5);
                        padding: get-vw(20) get-vw(10);
                    }

                    .Checkbox__label {
                        font-size: 22px;
                        display: block;
    
                        @include responsive($tabletSmall) {
                            @include font-vw(22);
                        }

                        @include responsive($deskXL){
                            font-size: 22px;
                        }

                        span {
                            margin-top: 10px;
                            line-height: 1.3;
                        }
                    }
                }
            }
        }

        .bloc_cta {
            background-color: $magenta;
            color: $white;
            font-weight: 500;
            font-size: 20px;
            line-height: 1.4;
            padding: 80px 20px 50px;
            border-radius: 15px;
            text-align: center;

            @include responsive($tabletSmall) {
                padding: get-vw(80) space(6) get-vw(50);
                @include font-vw(20);
            }

            @include responsive($deskXL){
                font-size: 20px;
            }

            .Btn {
                background-color: $green1;
                margin: 40px auto 0;

                @include responsive($tabletSmall) {
                    margin: get-vw(50) auto 0;
                }

                svg {
                    path {
                        fill: $red
                    }
                }
            }
        }
    }

    &__icon {
        margin-top: 90px;

        @include responsive($tabletSmall) {
            margin-top: get-vw(90);
            @include flex(center,space-between);
            gap: 20px;
        }

        article {
            @include responsive($tabletSmall, max) {
                margin: 0 auto 35px;
            }

            @include responsive($tabletSmall) {
                max-width: space(9);
                width: 100%;
            }

            img {
                margin: 0 auto 20px;
    
                @include responsive($tabletSmall) {
                    margin: 0 auto get-vw(32);
                }
            }
    
            h5 {
                font-size: 18px;
                font-weight: 600;
                text-align: center;
    
                @include responsive($tabletSmall) {
                  @include font-vw(18);
                }

                @include responsive($deskXL){
                    font-size: 18px;
                }
            }
        }
    }
}