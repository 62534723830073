.Nav {

  &__container {
    pointer-events: all;

    .--animating & {
      pointer-events: none;
    }

    @include responsive($menuBreakpoint, max) {
      @include flex(flex-start, center);
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $base;
      color: $white;
      overflow-y: auto;
    }

    body:not(.showMenu) & {
      @include responsive($menuBreakpoint, max) {
        display: none;
      }
    }
  }

  &__main {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include responsive($tabletSmall, max) {
      paddin-left: 24px;
      paddin-right: 24px;
    }

    @include responsive($menuBreakpoint, max) {
      width: 100%;
      padding: 200px calc(100vw / 24) 40px;
    }

    @include responsive($menuBreakpoint) {
      padding: 0;
      @include flex(center,center);
      gap: 20px;
    }

    @include responsive($desk) {
      gap: 32px;
    }
  }

  &__item {
    @include responsive($menuBreakpoint, max) {
      color: $pink;
      font-family: $title-font;
      font-size: 34px;
      line-height: 1;
      margin-bottom: 30px;
    }

    @include responsive($menuBreakpoint) {
      display: inline-block;
    }
  }

  &__link {
    position: relative;
    transition: filter 400ms $easeOutQuad;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.3;

    @include responsive($menuBreakpoint, max){
      font-size: 32px;
    }

    span {
      line-height: 1.2;
    }

    &.--active,
    &:focus,
    &:hover {
      filter: opacity(1);

      &:after {
        @include responsive($menuBreakpoint) {
          transform: scaleX(1);
        }
      }
    }

    &.--active {
      cursor: not-allowed;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: $base;
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform 400ms $easeOutQuad;

      html.--dark & {
        background: $white;
      }

      body.showMenu & {
        @include responsive($menuBreakpoint, max) {
          background: $white;
        }
      }
    }
  }

  &__sub {
    position: relative;

    &:not(.--open) {
      @include responsive($menuBreakpoint) {
        .submenu {
          pointer-events: none;
          transform: translateY(40px);
          opacity: 0;

          > ul li a {
            transform: translateY(100%);
            transition-delay: 0ms!important;
          }

          .Socials {
            pointer-events: none;
            opacity: 0;
            transform: translateY(20px);
            transition-delay: 0ms;
          }
        }
      }
    }

    &.--open {
      
      button {
        border-color: $red!important;

        span {
          opacity: 0;
          transform: translateY(-20%);
          &+span {
            opacity: 1;
            transform: translateY(0%);
          }
        }

        .arrow {
          transform: translateY(-50%) rotate(-180deg);
          svg path {
            stroke: $red;
          }
        }
      }
    }

    @include responsive($menuBreakpoint,max) {
      margin-top: 10px;
      pointer-events: all;
    }

    button {
      border: 1px solid $beige;
      border-radius: 38px;
      font-weight: 500;
      font-size: 15px;
      position: relative;
      z-index: 2;
      overflow: hidden;
      transition: all 240ms $ease;

      @include responsive($menuBreakpoint,max) {
        display: none;
      }

      span {
        padding: 9px 40px 9px 15px;
        transition: opacity 240ms $ease, transform 420ms $ease;

        &+span {
          color: $red;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transform: translateY(20%);
        }
      }

      .arrow {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%) rotate(0deg);

        svg {
          fill: none;
          path {
            stroke: $beige-d;
          }
        }
      }
    }

    .submenu { 
      @include responsive($menuBreakpoint) {
        position: absolute;
        z-index: 1;
        top: -24px;
        left: -24px;
        padding: 32px;
        padding-top: 94px;
        background-color: $white;
        border-radius: 15px;
        transition: transform 420ms $ease;
      }

      > ul {

        li {
          overflow: hidden;

          &+li {
            margin-top: 6px;
          }

          @for $i from 1 to 10 {
            &:nth-child(#{$i}) a { transition-delay: $i * 0.05s; }
          }

          a {
            display: inline-block;
            line-height: 1.2;
            white-space: nowrap;    
            font-size: 20px;
            transition: transform 640ms $ease;

            @include noTouchHover() {
              color: $green2;
            }

            &:after {
              display: none;
            }
    
            @include responsive($menuBreakpoint) {
              @include font-vw(48);
              font-family: $title-font;
            }

            @include responsive($deskXL){
                font-size: 48px;
            }

            @include responsive($menuBreakpoint, max) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .Socials {
      pointer-events: all;
      margin-top: 80px;
      transition: transform 420ms $ease 420ms, opacity 420ms $ease 420ms;

      ul {
        position: relative;
        padding: 0;

        li {
          margin: 0;

          .Icon {
            width: 32px;
            height: 32px;

            &.--linkedin {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}
