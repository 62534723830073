@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

::selection {
  background-color: rgba(153, 153, 153, 0.2);
}

html {
  font-size: 100%;
  // user-select: none;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-feature-settings: 'liga' off;
  scroll-behavior: initial;

  &.--no-events{
    *{ pointer-events: none !important }
  }
}

html,
body {
  width: 100%;
  min-height: 100%;
}

body {
  color: $base;
  background: $white;
  font: 400 16px $main-font;
  line-height: 1.6;

  html.--dark & {
    color: $white;
    background: $black;
  }

  html.no-js & {
    visibility: hidden;
    background: $base;
    opacity: 0;
  }

  html:not(.no-js) &.--preloading {
    visibility: hidden;
    background: $peach;
    opacity: 0;
  }

  html:not(.no-js) &.--loading,
  html:not(.no-js) &.--adding-to-cart {
		> * { cursor: progress }
  }

  &.show-grid:after {
    content: "";
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    width: calc(100% + 1px);
    height: 100vh;
    background-image: repeating-linear-gradient(90deg, transparent, transparent calc(calc(100% / $base-grid) - 1px), $white calc(calc(100% / $base-grid) - 1px), $white calc(100% / $base-grid));
    background-size: 100% calc(100% / $base-grid);
    opacity: 0.1;
    mix-blend-mode: difference;
  }
}
