$tarteaucitron-bgcolor: $base;
$allowed-color: #20b55f;
$denied-color: #d44545;

#tarteaucitronRoot {

  &.tarteaucitronBeforeVisible {
    #tarteaucitronAlertBig {
      @include responsive($tabletSmall) {
        display: flex!important;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  #tarteaucitronBack {
    background: $black!important;
    opacity: 0.9!important;
  }

  &.tarteaucitronBeforeVisible #tarteaucitronAlertBig {
    @include responsive($tabletSmall) {
      display: block!important;
    }
  }

  #tarteaucitronAlertBig {
    background: $white!important;
    color: $base!important;
    padding: 20px!important;
    box-sizing: border-box!important;
    width: auto!important;
    border-radius: 20px;
    text-align: left!important;
    transform: rotate(-1deg);
    box-shadow: 0 20px 50px rgba( 0, 0 , 0 , 0.1);
    bottom: 20px!important;
    left: 20px!important;
    max-width: calc(100% - 60px);

    @include responsive($tabletSmall) {
      bottom: space(1)!important;
      left: space(1)!important;
      padding: space(1)!important;
    }

    &:after {
      @include content();
      width: 20%;
      padding-top: 40%;
      background: url('../imgs/cookies_flower.svg') no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: rotate(3deg) translateX(50%);
    }
    
    #tarteaucitronDisclaimerAlert {
      display: block;
      margin-right: auto!important;
      font-size: 13px;
      display: block;
      text-align: center;
      margin-bottom: 10px;

      @include responsive($tabletSmall) {
        text-align: left;
        padding: 0;
      }

      span {
        color: $red;
        font-family: $title-font!important;
        font-size: 2rem;
        line-height: 1;
        display: block;
        max-width: 60%;
        margin-bottom: 5px;

        @include responsive($tabletSmall) {
          @include font-vw(32);
        }
      }

      p {
        display: block;
        max-width: 70%;
        margin-bottom: 10px;
      }
    }

    > button {
      margin: 0!important;
      background: transparent!important;
      font-size: 9px!important;
      letter-spacing: 0.1em;
      font-weight: bold;
      background: $green2!important;
      border: none !important;
      margin-top: 10px!important;
      padding: 12px 20px!important;
      color: $white!important;
      border-radius: 60px;
      text-transform: uppercase;

      @include responsive($tabletSmall) {
        margin-top: 0!important;
        margin-right: 6px!important;
        white-space: nowrap;
      }

      &#tarteaucitronCloseAlert {
        text-decoration: underline!important;
        color: $green2!important;
        background: 0!important;
      }

      span {
        display:none!important;
      }
    }
  }

  #tarteaucitronClosePanel {
    background: $tarteaucitron-bgcolor!important;
    border-color: $tarteaucitron-bgcolor!important;
  }

  #tarteaucitronServices {
    box-shadow: none!important;

    .tarteaucitronMainLine,
    .tarteaucitronTitle button {
      background: $tarteaucitron-bgcolor!important;
      border-color: $tarteaucitron-bgcolor!important;
    }

    .tarteaucitronAllow, .tarteaucitronDeny {
      margin: 0!important;
      background: $white!important;
      font-size: 9px!important;
      letter-spacing: 0.1em;
      font-weight: bold;
      margin-left: 10px!important;
      padding: 10px 20px!important;
      color: $tarteaucitron-bgcolor!important;
      text-transform: uppercase;

      &:hover {
        opacity: 1;
      }

      span {
        display: none!important;
      }
    }

    ul .tarteaucitronLine.tarteaucitronIsAllowed { border-color: $allowed-color!important; }
    ul .tarteaucitronLine.tarteaucitronIsDenied { border-color: $denied-color!important; }

    #tarteaucitronInfo {
      background: 0!important;
    }
  }
}
